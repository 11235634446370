<template>
  <div class="nav-link tag">
    <router-link :to="{ name: 'Tag', params: { tag: tag.id } }"><IconHash />{{ tag.name }}</router-link>
    <div class="tag-toggle" :class="{ open: isOpen }" v-if="childTags.length" @click="toggleOpen"></div>
    <div class="nav-children" v-if="isOpen">
      <draggable :list="childTags" @change="onDragChange" group="tags" ghost-class="ghost" class="dragArea">
        <TagItem v-for="tag in childTags" :key="tag.id" :tag="tag" />
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { getDropPosition } from '@/utils/tags';
import IconHash from '@/components/icons/Hash';

export default {
  name: 'TagItem',
  props: {
    tag: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    draggable,
    IconHash,
  },
  data: () => ({
    isOpen: true,
  }),
  computed: {
    childTags() {
      return this.$store.getters['tags/childTags'](this.tag.id);
    },
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    onDragChange($event) {
      const { element, newIndex } = $event.moved || $event.added || {};
      if (element) {
        const parentId = $event.added ? this.tag.id : element.parent;
        // Dispatch edit to note
        this.$store.dispatch('tags/edit', {
          ...element,
          parent: parentId,
          position: getDropPosition(this.childTags, newIndex),
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nav-link {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 0px;
  position: relative;
  font-weight: 400;

  a {
    display: flex;
    align-items: center;
    color: var(--sidebar-text-color);
    text-decoration: none;
    position: relative;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .icon {
      cursor: move;
      width: 30px;
      height: 30px;
      margin-right: 6px;
      display: grid;
      place-items: center;
      border-radius: 8px;

      /deep/ svg {
        fill: var(--sidebar-text-color);
        width: 14px;
        height: 14px;
      }
    }
  }

  .router-link-exact-active .icon,
  & a:hover .icon {
    background: var(--sidebar-menu-bg-color);
  }

  .router-link-exact-active,
  & a:hover {
    color: var(--sidebar-text-active-color);
  }

  .nav-children {
    padding-left: 1rem;
  }
}
.tag-toggle {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: var(--sidebar-menu-bg-color);
  }

  &:after {
    content: '';
    position: absolute;
    top: 13px;
    left: 10px;
    border: solid 4px;
    border-color: var(--sidebar-text-color) transparent transparent transparent;
  }

  &.open {
    &:after {
      top: 7px;
      transform: rotate(180deg);
    }
  }
}
</style>

<style lang="scss">
.tag.ghost {
  background: var(--drop-target-background-color);
  height: 4px;

  // hide content
  > * {
    display: none !important;
  }
}
</style>
