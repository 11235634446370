<template>
  <div class="task-board nl">
    <NotesGroup>
      <h2>Your Tasks</h2>
      <div class="tasks first">
        <div class="task-title" @click="toggleTodo()">
          <h4><IconToggle color="#2C3E50" :class="{ closed: !todoListOpen }" /> Todo</h4>
          <span class="task-title-msg">
            {{ todoTasks.length }} {{ pluralize(todoTasks.length, 'task') }} to complete
          </span>
        </div>
        <div v-if="todoListOpen" class="task-content">
          <TaskListGroup
            :tasks="todoTasks"
            :hide-child-notes="false"
            group-name="todo"
            zone="note"
            class="task-group"
          />
          <AddNote label="Add a task" @addNote="onAddTask()" />
        </div>
      </div>
      <div class="tasks">
        <div class="task-title" @click="toggleTodoLater()">
          <h4><IconToggle color="#2C3E50" :class="{ closed: !todoLaterListOpen }" /> Todo Later</h4>
          <span class="task-title-msg">
            {{ todoLaterTasks.length }} {{ pluralize(todoLaterTasks.length, 'task') }} to complete
          </span>
        </div>
        <div v-if="todoLaterListOpen" class="task-content">
          <TaskListGroup
            :tasks="todoLaterTasks"
            :hide-child-notes="false"
            group-name="later"
            zone="note"
            empty-message="Drag tasks here that aren't urgent"
          />
          <AddNote label="Add a task" @addNote="onAddTask(TODO_LATER)" />
        </div>
      </div>
      <div class="tasks last">
        <div class="task-title" @click="completedOpen = !completedOpen">
          <h4><IconToggle color="#2C3E50" :class="{ closed: !completedOpen }" /> Completed</h4>
          <span class="task-title-msg">
            {{ completedTasks.length }} completed {{ pluralize(completedTasks.length, 'task') }}
          </span>
        </div>
        <div v-if="completedOpen" class="task-content">
          <TaskListGroup
            :tasks="completedTasks"
            group-name="completed"
            zone="note"
            :readonly="true"
            empty-message="Completed tasks will appear here"
          />
        </div>
      </div>
    </NotesGroup>
  </div>
</template>

<script>
import { TASK_GROUP_TODO, TASK_GROUP_LATER } from '@/common/constants';
import { addNoteTolist, removeDuplicateNotes } from '@/utils/notes';
import AddNote from '@/components/AddNote';
import NotesGroup from '@/components/NotesGroup';
import TaskListGroup from '@/components/TaskListGroup';
import IconToggle from '@/components/icons/Toggle';

export default {
  name: 'TaskBoard',
  components: {
    AddNote,
    NotesGroup,
    TaskListGroup,
    IconToggle,
  },
  data: () => ({
    completedOpen: false,
    TODO_NOW: TASK_GROUP_TODO,
    TODO_LATER: TASK_GROUP_LATER,
  }),
  computed: {
    todoListOpen() {
      return this.$store.getters.getConfig.todoListOpen;
    },
    todoLaterListOpen() {
      return this.$store.getters.getConfig.todoLaterListOpen;
    },
    tasks() {
      const tasks = this.$store.getters['notes/notesByType']('task');
      // remove any duplicate Tasks - this happens when a child task has a parent task and it appears twice.
      // This will only show the Parent task and assumes the child is therefore shown.
      return removeDuplicateNotes(tasks);
    },
    todoTasks() {
      const filtered = this.tasks.filter(
        (note) => !note.task.completed && (!note.task.group || note.task.group === this.TODO_NOW)
      );
      return filtered.sort((a, b) => a.task.position - b.task.position);
    },
    todoLaterTasks() {
      const filtered = this.tasks.filter((note) => !note.task.completed && note.task.group === this.TODO_LATER);
      return filtered.sort((a, b) => a.task.position - b.task.position);
    },
    completedTasks() {
      const match = this.tasks.filter((note) => note.task.completed);
      // Sort by Task Completion Date
      return match.sort((a, b) => b.task.completedDate.localeCompare(a.task.completedDate));
    },
  },
  methods: {
    pluralize(val, string) {
      return val === 1 ? string : `${string}s`;
    },
    onAddTask(taskGroup = '') {
      // TaskGroup: is set to define if it is todo now or later
      // ListDate: is set to stop a date getting generated and it appearing in the Daily Notes
      addNoteTolist({
        type: 'task',
        taskGroup,
        listDate: 'task',
      });
      // Log event
      this.$gtag.event('AddNote', {
        event_category: 'CTA',
        event_label: 'TaskBoard',
      });
    },
    toggleTodo() {
      this.$store.dispatch('updateConfig', { todoListOpen: !this.todoListOpen });
    },
    toggleTodoLater() {
      this.$store.dispatch('updateConfig', { todoLaterListOpen: !this.todoLaterListOpen });
    },
  },
};
</script>

<style scoped lang="scss">
.tasks {
  padding-bottom: 1rem;

  &.first .task-content {
    min-height: 30vh;
  }

  &.last {
    padding-bottom: 0;
  }
}
.task-title {
  line-height: 1;
  border-bottom: solid 1px #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0;

  h4 {
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 4px;
      transition: transform linear 150ms;

      &.closed {
        transform: rotate(-90deg);
      }
    }
  }
}
.task-title-msg {
  font-size: 14px;
  opacity: 0.6;
}
.task-content {
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: -4px;
}
.task-group {
  margin-bottom: 0.25rem;
}
</style>
