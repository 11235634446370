<template>
  <div class="listing nl">
    <NotesGroup>
      <div v-for="(notes, key, index) in groupedNotes" :key="key">
        <NotesLayout
          :title="getTitle(index)"
          :subTitle="formatDate(key)"
          :listDate="key"
          :compact="true"
          :emptyMessage="'No results found for \'' + query + '\''"
          :showEmptyMessage="true"
          :showEmptyMessageAddButton="false"
          :readonly="true"
          :forceOpen="true"
          :notes="notes"
        />
      </div>
    </NotesGroup>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { uniq } from 'lodash';
import { groupNotesBy, removeDuplicateNotes } from '@/utils/notes';
import NotesGroup from '@/components/NotesGroup';
import NotesLayout from '@/components/NotesLayout';

export default {
  name: 'SearchResults',
  components: {
    NotesGroup,
    NotesLayout,
  },
  computed: {
    query() {
      return this.$route.params.query.toLowerCase();
    },
    groupedNotes: {
      get() {
        const query = this.query;
        if (query.length > 1) {
          const allNotes = this.$store.getters['notes/notes'];
          // Find string matches in all notes (make into getter?)
          const results = allNotes.filter((note) => note.content.toLowerCase().includes(query));
          // Parse results to pull out Root Ancestor notes (we want to show the entire tree here)
          // const finalResults = this.getSearchResultsParents(results, allNotes);
          const finalResults = removeDuplicateNotes(results);

          if (finalResults && finalResults.length) {
            // Sort by Modifed Date
            const sortedNotes = finalResults.sort((a, b) => b.createdDate.localeCompare(a.createdDate));
            return groupNotesBy(sortedNotes, 'listDate');
          } else {
            return { null: [] };
          }
        } else {
          return { null: [] };
        }
      },
      set() {},
    },
  },
  methods: {
    getSearchResultsParents(results, notes) {
      const parentResults = [];
      // Get the Ancestor Tree
      const tree = this.$store.getters['noteTree/tree'];
      // Loop through searched results and build array of parent IDS
      results.forEach((item) => {
        const ancestors = tree[item.id];
        const [lastAncestor] = ancestors.slice(-1);
        parentResults.push(lastAncestor || item.id);
      });
      // get rid of duplicates
      const uniqueParentResults = uniq(parentResults);
      // Filter notes array to return matches
      return notes.filter((item) => uniqueParentResults.indexOf(item.id) > -1);
    },
    formatDate(date) {
      if (date && date != 'null') {
        return dayjs(date).format('D MMM YYYY');
      }
      return null;
    },
    getTitle(index) {
      return index === 0 ? 'Search results' : '';
    },
  },
};
</script>
