<template>
  <transition name="modal-fade">
    <div v-scroll-lock="showContent" class="modal-box">
      <div class="modal-scroller">
        <transition name="slide-up">
          <div v-if="showContent" class="modal">
            <div class="modal-close" @click="closeModal()">
              <IconClose />
            </div>
            <h2>Keyboard Shortcuts</h2>
            <div class="columns">
              <div class="shortcuts">
                <h4>Note editing</h4>
                <p class="msg">Use these when editing a note</p>
                <div class="shortcut-row">
                  <span class="label">Add a note</span>
                  <span class="keys"><kbd>enter</kbd></span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Add a note and indent</span>
                  <span class="keys"><kbd>shift</kbd> + <kbd>enter</kbd></span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Indent note</span>
                  <span class="keys"><kbd>tab</kbd></span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Outdent note</span>
                  <span class="keys"><kbd>shift</kbd> + <kbd>tab</kbd></span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Zoom into note</span>
                  <span class="keys"><kbd>shift</kbd> + <kbd>→</kbd></span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Open parent note</span>
                  <span class="keys"><kbd>shift</kbd> + <kbd>←</kbd></span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Expand / Collapse note</span>
                  <span class="keys">
                    <kbd>{{ metaKey }}</kbd> + <kbd>↑ or ↓</kbd>
                  </span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Delete note (if empty)</span>
                  <span class="keys"><kbd>esc</kbd> or <kbd>backspace</kbd></span>
                </div>
              </div>

              <div class="shortcuts">
                <h4>Slash commands</h4>
                <p class="msg">Type these in a note and press enter</p>
                <div class="shortcut-row">
                  <span class="label">Convert to task</span>
                  <span class="keys"><kbd>/task</kbd> or <kbd>/t</kbd></span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Convert to meeting</span>
                  <span class="keys"><kbd>/meeting</kbd> or <kbd>/m</kbd></span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Convert to note</span>
                  <span class="keys"><kbd>/note</kbd> or <kbd>/n</kbd></span>
                </div>

                <h4>Global</h4>
                <p class="msg">Use these anywhere</p>
                <div class="shortcut-row">
                  <span class="label">Search</span>
                  <span class="keys">
                    <kbd>{{ metaKey }}</kbd> + <kbd>k</kbd>
                  </span>
                </div>
                <div class="shortcut-row">
                  <span class="label">Select all notes</span>
                  <span class="keys">
                    <kbd>{{ metaKey }}</kbd> + <kbd>a</kbd>
                  </span>
                </div>
                <div class="shortcut-row">
                  <span class="label">See shortcuts</span>
                  <span class="keys">
                    <kbd>{{ metaKey }}</kbd> + <kbd>?</kbd>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="overlay" @click="closeModal()" />
    </div>
  </transition>
</template>

<script>
import IconClose from '@/components/icons/Close';
import { isMacOs } from '@/utils';

export default {
  name: 'KeyboardShortcuts',
  components: {
    IconClose,
  },
  data: () => ({
    showContent: false,
  }),
  computed: {
    metaKey() {
      return isMacOs ? '⌘' : 'ctrl';
    },
  },
  mounted() {
    this.showContent = true;
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    closeModal() {
      this.showContent = false;
      this.$emit('toggleShortcuts');
    },
    handleKeydown(e) {
      if (e.key == 'Escape') {
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $zIndexModal;
  overflow-y: auto;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--modal-overlay-background-color);
}
.modal-scroller {
  padding: 3rem;
  display: flex;
  justify-content: center;
}
.modal {
  width: 100%;
  max-width: 700px;
  height: auto;
  padding: 3rem 3rem;
  background: var(--modal-background-color);
  position: relative;
  z-index: 102;
  border-radius: 8px;
}
.modal-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: var(--sidebar-menu-bg-color);
  }

  /deep/ svg {
    width: 20px;
    height: 20px;
    fill: var(--text-color);
  }
}
.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
}
.shortcuts {
  list-style: none;
  padding: 0;
  font-size: 14px;
  margin-top: 1.5rem;
}
.shortcut-row {
  padding: 0.5rem 0;
  border-bottom: solid 1px #eee;
  display: flex;
}
.shortcut-row + h4 {
  margin-top: 2rem;
}
.label {
  flex: 1;
  padding-right: 0.5rem;
}
.keys {
  text-align: right;
}

.msg {
  opacity: 0.75;
  font-style: italic;
  margin-top: -0.5rem;
}

/* Animations */

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.2s ease;
}

.slide-up-enter,
.slide-up-leave-active {
  opacity: 0;
  transform: translateY(30px);
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease;
}
</style>
