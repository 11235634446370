<template>
  <div class="listing nl">
    <NotesGroup>
      <div v-for="(notes, key, index) in groupedNotes" :key="key">
        <NotesLayout
          :title="getTitle(index)"
          :subTitle="formatDate(key)"
          :listDate="key"
          emptyMessage="Sorry we couldn't find any notes with that #tag :("
          :childNotesOnly="true"
          :compact="true"
          :showAddNote="false"
          :showEmptyMessageAddButton="false"
          :showEmptyMessage="true"
          :notes="notes"
        />
      </div>
    </NotesGroup>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { removeDuplicateNotes, groupNotesBy } from '@/utils/notes';
import NotesGroup from '@/components/NotesGroup';
import NotesLayout from '@/components/NotesLayout';

export default {
  name: 'Tag',
  components: {
    NotesGroup,
    NotesLayout,
  },
  computed: {
    tag() {
      return this.$route.params.tag.toLowerCase();
    },
    groupedNotes() {
      const notes = this.$store.getters['notes/notesByTag'](this.tag);
      // remove any duplicate Notes - this happens when a child task has a parent task and it appears twice.
      // This will only show the Parent task and assumes the child is therefore shown.
      const unique = removeDuplicateNotes(notes);
      if (unique.length) {
        // Sort by Last created Date
        const uniqueSorted = unique.sort((a, b) => b.createdDate.localeCompare(a.createdDate));
        // group notes by date
        return groupNotesBy(uniqueSorted, 'listDate');
      } else {
        return { null: [] };
      }
    },
  },
  methods: {
    formatDate(date) {
      if (date && date != 'null') {
        return dayjs(date).format('D MMM YYYY');
      }
      return null;
    },
    getTitle(index) {
      return index === 0 ? `#${this.tag}` : '';
    },
  },
};
</script>
