<template>
  <div class="menu-container">
    <transition name="open">
      <Menu v-if="open">
        <div class="menu-group">
          <div class="menu-title">About</div>
          <div class="menu-item clickable" @click="openWelcome">About Tueday</div>
          <a class="menu-item" href="" @click="openShortcutsMenu($event)"><IconCommand /> Shortcuts</a>
        </div>
        <div class="menu-group">
          <div class="menu-title">Settings</div>
          <a class="menu-item" href="" @click="toggleTheme($event)">
            <IconSun /> {{ darkMode ? 'Lightmode' : 'Darkmode' }}
          </a>
        </div>
        <div class="menu-group row">
          <router-link class="menu-item small" to="/about/privacy" @click.native="closeMenu">Privacy</router-link>
          <router-link class="menu-item small" to="/about/terms" @click.native="closeMenu">Terms</router-link>
        </div>
      </Menu>
    </transition>
    <div class="overlay" v-if="open" @click="closeMenu" />
    <KeyboardShortcuts v-if="showShortcuts" @toggleShortcuts="toggleShortcuts" />
  </div>
</template>

<script>
import { EventBus, OPEN_KEYBOARD_SHORTCUTS } from '@/event-bus';
import IconCommand from '@/components/icons/Command';
import IconSun from '@/components/icons/Sun';
import Menu from '@/components/Menu';
import KeyboardShortcuts from '@/components/KeyboardShortcuts';

export default {
  name: 'SettingsMenu',
  components: {
    IconCommand,
    IconSun,
    Menu,
    KeyboardShortcuts,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showShortcuts: false,
  }),
  computed: {
    darkMode() {
      return this.$store.getters.getSettings.darkMode;
    },
  },
  watch: {
    darkMode(isDark) {
      this.setTheme(isDark);
    },
  },
  created() {
    EventBus.$on(OPEN_KEYBOARD_SHORTCUTS, this.toggleShortcuts);
  },
  mounted() {
    // On Mount get and set Theme
    this.setTheme(this.darkMode);
  },
  methods: {
    openWelcome() {
      this.$store.dispatch('updateConfig', { welcomeOpen: true });
      this.closeMenu();
    },
    closeMenu() {
      this.$emit('toggleOpen');
    },
    toggleTheme(e) {
      e.preventDefault();
      // Dispatch change
      this.$store.dispatch('updateSetting', { darkMode: !this.darkMode });
      // Log event
      this.$gtag.event('ToggleDarkmode', {
        event_category: 'Settings',
      });
    },
    openShortcutsMenu(e) {
      e && e.preventDefault();
      this.closeMenu();
      this.toggleShortcuts();
    },
    toggleShortcuts() {
      this.showShortcuts = !this.showShortcuts;
    },
    setTheme(isDark) {
      const htmlElement = document.documentElement;
      const theme = isDark ? 'dark' : 'light';
      htmlElement.setAttribute('theme', theme);
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  position: absolute;
  top: 20%;
  right: 1rem;
  left: auto;
  z-index: 100;
  transform-origin: 90% 0%;
}

.open-enter-active,
.open-leave-active {
  transition: all 0.2s;
  opacity: 1;
  transform: scale(1);
}
.open-enter, .open-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0.9);
}
.overlay {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19;
}
</style>
