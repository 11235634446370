<template>
  <div class="listing nl">
    <NotesGroup>
      <NotesLayout
        title="Bookmarks"
        emptyMessage="Notes that you Bookmark will appear here so you can easily find them again."
        :childNotesOnly="true"
        :showAddNote="false"
        :showEmptyMessageAddButton="false"
        :showEmptyMessage="true"
        :notes="notes"
      />
    </NotesGroup>
  </div>
</template>

<script>
import { removeDuplicateNotes } from '@/utils/notes';
import NotesGroup from '@/components/NotesGroup';
import NotesLayout from '@/components/NotesLayout';

export default {
  name: 'Bookmarks',
  components: {
    NotesGroup,
    NotesLayout,
  },
  computed: {
    notes() {
      const notes = this.$store.getters['notes/notesBookmarked'];
      // remove any duplicate Tasks - this happens when a child task has a parent task and it appears twice.
      // This will only show the Parent task and assumes the child is therefore shown.
      const unique = removeDuplicateNotes(notes);
      // Sort by Last created Date
      return unique.sort((a, b) => b.createdDate.localeCompare(a.createdDate));
    },
  },
};
</script>
