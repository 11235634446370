<template>
  <div class="nl">
    <virtual-list
      class="list"
      :data-key="'uid'"
      :keeps="4"
      :data-sources="weeks"
      :data-component="weekComponent"
      :page-mode="true"
      v-on:tobottom="addPage"
      ref="dailyNotes"
    />
    <BackToTopBtn :class="[hasScrolledDown ? 'show' : 'hide']" @onClick="toTop()" />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import VirtualList from 'vue-virtual-scroll-list';

import BackToTopBtn from '@/components/BackToTopBtn';
import Week from '@/components/Week';

const RESET = 0;

export default {
  name: 'DailyNotes',
  components: {
    BackToTopBtn,
    VirtualList,
  },
  data: () => ({
    today: null,
    page: 0,
    weekComponent: Week,
    weeks: [],
    hasScrolledDown: false,
  }),
  watch: {
    today(newVal, oldVal) {
      // Checking for date changes
      if (newVal !== oldVal) {
        this.resetToday();
      }
    },
  },
  created() {
    const refreshInterval = 10 * 1000; // 10 minutes
    this.updateToday();
    setInterval(this.updateToday, refreshInterval);
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    updateToday() {
      this.today = dayjs().format('YYYY-MM-DD');
    },
    resetToday() {
      window.scrollTo(0, 0);
      this.$refs.dailyNotes.reset();
      this.page = RESET;
      this.weeks = [this.getWeek(this.page)];
    },
    getWeek(page) {
      // navigate to end of week, then subtract a week * the page
      // pages start at 0, so the first week substracts 0
      const week = dayjs(this.today).endOf('week').subtract(page, 'week');
      const thisWeek = page === 0 ? dayjs(this.today) : week;
      let lengthOfWeek = dayjs(thisWeek).day() + 1;

      if (lengthOfWeek < 6) {
        // if the week is partially complete,
        // add the next week to it
        lengthOfWeek = lengthOfWeek + 7;
        this.page = this.page + 1;
      }

      return {
        uid: `week-${page}`,
        endOfWeek: thisWeek.format(),
        weekLength: lengthOfWeek,
        page,
      };
    },
    addPage() {
      this.page = this.page + 1;
      this.weeks = this.weeks.concat(this.getWeek(this.page));
    },
    toTop() {
      this.$refs.dailyNotes.scrollToIndex(0);
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      this.hasScrolledDown = window.scrollY > window.innerHeight * 2;
    },
  },
};
</script>
