import Vue from 'vue';

// Constants
export const DOC_CLICK = 'DOC_CLICK';
export const FOCUS_SEARCH = 'FOCUS_SEARCH';
export const OPEN_KEYBOARD_SHORTCUTS = 'OPEN_KEYBOARD_SHORTCUTS';
export const SELECT_NOTES = 'SELECT_NOTES';

// EVENT BUS
export const EventBus = new Vue();
