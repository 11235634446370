<template>
  <div v-if="shouldShow" class="badge">
    {{ taskCount }}
  </div>
</template>

<script>
export default {
  name: 'TaskBadge',
  props: {
    count: {
      type: Number,
      require: false,
      default: 0,
    },
    hideIfZero: {
      type: Boolean,
      require: false,
      default: false,
    },
    getCountFromStore: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  computed: {
    shouldShow() {
      return this.hideIfZero ? this.taskCount > 0 : true;
    },
    getTaskCount() {
      return this.$store.getters['notes/notesAsTaskCount'];
    },
    taskCount() {
      return this.getCountFromStore ? this.getTaskCount : this.count;
    },
  },
};
</script>

<style scoped lang="scss">
.badge {
  background: $color_primary;
  color: $color_text_dark;
  font-size: 8px;
  min-width: 10px;
  height: 10px;
  border-radius: 5px;
  line-height: 10px;
  padding: 0 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
