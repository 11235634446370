import dayjs from 'dayjs';

const data = [
  {
    id: 'h01ektn6',
    parent: null,
    content: '👋  Welcome! Tueday is a rethink of how we capture personal notes, ideas, tasks and events.',
    type: 'note',
    position: 200,
    readOnly: true,
  },
  {
    id: 'okujnvfg',
    parent: 'h01ektn6',
    content: 'Notes can be written down in a haphazard way, and organised later.',
    type: 'note',
    position: 100,
    readOnly: true,
  },
  {
    id: 'okujnvfd',
    parent: 'okujnvfg',
    content:
      "By default your notes are organised by when they were written and each day you'll start off with a new blank slate.",
    type: 'note',
    position: 200,
    readOnly: true,
  },
  {
    id: '08cqs2g7',
    parent: 'okujnvfg',
    content:
      'We believe its better to just write something down than spend the time trying to find the perfect place to store a note or writing it on a scrap of paper.',
    type: 'note',
    position: 100,
    readOnly: true,
  },
  {
    id: 'okujnvfa',
    parent: 'h01ektn6',
    content: 'You can organise notes into common topics using #tags',
    tags: ['tags'],
    type: 'note',
    position: 200,
    readOnly: true,
  },
  {
    id: 'okujnvfd',
    parent: 'h01ektn6',
    content: 'Or drag and drop them, using the little handle on the left',
    tags: ['tags'],
    type: 'note',
    position: 250,
    readOnly: true,
  },
  {
    id: 'ay3nuhgw',
    parent: null,
    content: 'Notes can be converted to Tasks by pressing the Bullet icon on the left.',
    type: 'note',
    position: 200,
    readOnly: true,
  },
  {
    id: 'wvnvx9jh',
    parent: '6d4z7f59',
    content: "Press 'Tab' to indent a note under the previous one and 'Shift+Tab' to move them back out",
    type: 'note',
    position: 200,
    readOnly: true,
  },
  {
    id: '7azoqnqh',
    parent: 'ay3nuhgw',
    content: 'Tasks will appear in the menu on the left so you can easily find them.',
    type: 'task',
    position: 300,
    readOnly: true,
    task: {
      completed: false,
      dueDate: null,
      completedDate: null,
      position: 100,
    },
  },
  {
    id: 'wvnvx9jj',
    parent: 'ay3nuhgw',
    content: 'To mark a Task as complete, just click the bullet again and complete it!',
    type: 'task',
    position: 350,
    readOnly: true,
    task: {
      completed: true,
      dueDate: null,
      completedDate: null,
      position: 150,
    },
  },
  {
    id: '6d4z7f59',
    parent: null,
    content: 'Notes can also be converted to Meetings.',
    type: 'event',
    position: 400,
    readOnly: true,
  },
  {
    id: 'f5lsc6yb',
    parent: '6d4z7f59',
    content: 'Use Meetings to signify a meeting or significant event that you want to document.',
    type: 'note',
    position: 100,
    readOnly: true,
  },
  {
    id: '6d4z7f5ff',
    parent: null,
    content: "To get started, just press 'Add a note' at the top and start typing!",
    type: 'note',
    position: 500,
    readOnly: true,
  },
];

const getSeedNotes = () => {
  const now = dayjs();
  const nowStr = now.format();
  return data.map((note) => {
    return {
      ...note,
      createdDate: nowStr,
      modifiedDate: nowStr,
      listDate: now.format('YYYY-MM-DD'),
    };
  });
};

export { getSeedNotes };
