import Router from '@/router';

const generateId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 8 + 5 characters
  // after the decimal.
  // ex. y0s2i95b-dwzzn
  return `${Math.random().toString(36).substring(2, 10)}-${Math.random().toString(36).substring(2, 7)}`;
};

// Builds a reverse Tree structure of the notes
// Results in an object that contains key value pairs
// Example
// { noteId: [directAncestor, nextAncestor, rootAncestor] }
const buildNoteTree = (arr) => {
  const table = {};
  // first pass
  arr.forEach((value) => {
    table[value.id] = value.parent ? [value.parent] : [];
  });

  // second pass
  for (const [key, value] of Object.entries(table)) {
    const parents = findParentNode(value, table);
    if (parents && parents.length) {
      table[key] = [...parents];
    }
  }
  return table;
};

// Traverses the NoteTree to fnd the Parent
const findParentNode = (parents, table) => {
  if (!parents) return [];
  const [topParent] = parents.slice(-1);
  if (topParent) {
    const nextParents = table[topParent];
    return [...parents, ...findParentNode(nextParents, table)];
  } else {
    return parents;
  }
};

// Is mac os
const isMacOs = () => {
  return navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
};

// Is mobile UI
const isMobile = () => {
  return window.screen.width <= 680;
};

// Handle internal links that aren't wrapped in a vue router
const handleInternalAnchorClick = (event) => {
  // ensure we use the link, in case the click has been received by a subelement
  let { target } = event;
  while (target && target.tagName !== 'A') target = target.parentNode;
  // handle only links that occur inside the component and do not reference external resources
  if (target && target.matches("a:not([href*='://'])") && target.href) {
    // some sanity checks taken from vue-router:
    // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
    const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event;
    // don't handle with control keys
    if (metaKey || altKey || ctrlKey || shiftKey) return;
    // don't handle when preventDefault called
    if (defaultPrevented) return;
    // don't handle right clicks
    if (button !== undefined && button !== 0) return;
    // don't handle if `target="_blank"`
    if (target && target.getAttribute) {
      const linkTarget = target.getAttribute('target');
      if (/\b_blank\b/i.test(linkTarget)) return;
    }
    // don't handle same page links/anchors
    const url = new URL(target.href);
    const to = url.pathname;
    if (window.location.pathname !== to && event.preventDefault) {
      event.preventDefault();
      Router.push(to);
    }
  }
};

export { generateId, buildNoteTree, handleInternalAnchorClick, isMacOs, isMobile };
