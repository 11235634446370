import store from '@/store';
import { SELECTED_NOTE_CLASS } from '@/utils/notes';

// Gets the Notes ContentEditable Block based on a note ID
const getNoteDomElement = (id) => {
  let noteId = id;
  // Check its a valid ID
  if (!id || (!id.includes('note-') && !id.includes('task-'))) {
    console.warn('Missing ZONE id for:', id);
    // Fix if not and assume is Note
    noteId = `note-${id}`;
  }
  // Query for element
  const element = document.getElementById(noteId);
  if (!element) {
    // Warn if not found
    console.error('!! Cannot find node', id);
    return false;
  }
  return element;
};

// Finds the Previous Sibling .note
const getPreviousSiblingNote = (noteId) => {
  // Get Target Dom Note
  const targetElement = getNoteDomElement(noteId);
  if (targetElement) {
    const note = targetElement.closest('.note');
    // Get the Previous Sibling
    const previousSibling = note.previousSibling;
    if (previousSibling) {
      // If there is one, find the primary note ID
      const siblingNoteCED = previousSibling.querySelectorAll(':scope .note-content .ced');
      // Extract the note ID and return
      return siblingNoteCED[0].dataset.id;
    }
  }
  return null;
};

// Finds the Previous notes note
const getPreviousAncestorNote = (noteId) => {
  // Get Target Dom Note
  const targetElement = getNoteDomElement(noteId);
  if (targetElement) {
    const note = targetElement.closest('.note');
    // Get the notes Parent node
    const parentNote = note.parentElement.closest('.note');
    if (parentNote) {
      // Get the Parent Parent
      const parentParentNote = parentNote.parentElement.closest('.note');
      if (parentParentNote) {
        // If there is one, find the primary note ID
        const siblingNoteCED = parentParentNote.querySelectorAll(':scope .note-content .ced');
        // Extract the note ID and return
        return siblingNoteCED[0].dataset.id;
      }
    }
  }
  return null;
};

// Traverse DOM to find all children based on a specific noteID
const findAllChildrenNotes = (noteId) => {
  const foundChildren = [];
  // Get Target Dom Note
  const targetElement = getNoteDomElement(noteId);
  const closestNote = targetElement.closest('.note');
  // Search within to find any notes that are decendants
  const childrenNotes = closestNote.querySelectorAll(':scope .children .ced');
  // Loop through them and extract IDS
  for (var i = 0; i < childrenNotes.length; i++) {
    foundChildren.push(childrenNotes[i].dataset.id);
  }
  return foundChildren;
};

// Returns the top and left of an element ref passed in
const getRefPosition = (targetRef) => {
  if (targetRef) {
    const { top, left } = targetRef.getBoundingClientRect();
    return { top, left };
  }
  return { top: 0, left: 0 };
};

// Handles execCommand in the browser
const execCommand = (command, value = null) => {
  document.execCommand(command, false, value);
};

// extracts text from notes and pushes into array
const extractTextFromNotes = (notes) => {
  return [...notes].map((element) => {
    // get note from store
    const nodeId = element.getAttribute('data-id');
    const note = store.getters['notes/noteById'](nodeId);
    // determine if its a task and its completed
    const isCompletedTask = note.type === 'task' && note.task.completed;
    const type = isCompletedTask ? 'completedTask' : note.type;

    return {
      type,
      level: element.getAttribute('level'),
      content: element.textContent,
    };
  });
};

// Finds selected notes in DOM
const getSelectedNotes = () => {
  // return document.getElementsByClassName('nSelected');
  return document.getElementsByClassName(SELECTED_NOTE_CLASS);
};

// Finds selected notes and returns as array of text
const getSelectedNotesAsText = () => {
  const selectedNotes = getSelectedNotes();
  return extractTextFromNotes(selectedNotes);
};

export {
  execCommand,
  getNoteDomElement,
  getPreviousSiblingNote,
  getPreviousAncestorNote,
  getSelectedNotesAsText,
  findAllChildrenNotes,
  getRefPosition,
};
