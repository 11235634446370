<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.70711 3.29289C9.31658 2.90237 8.68342 2.90237 8.29289 3.29289L3.29289 8.29289C2.90237 8.68342 2.90237 9.31658 3.29289 9.70711C3.68342 10.0976 4.31658 10.0976 4.70711 9.70711L8 6.41421V16C8 17.3261 8.52678 18.5979 9.46447 19.5355C10.4021 20.4732 11.6739 21 13 21H20C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19H13C12.2044 19 11.4413 18.6839 10.8787 18.1213C10.3161 17.5587 10 16.7956 10 16V6.41421L13.2929 9.70711C13.6834 10.0976 14.3166 10.0976 14.7071 9.70711C15.0976 9.31658 15.0976 8.68342 14.7071 8.29289L9.70711 3.29289Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconArrowRightUp',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
