<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg id="icon-calendar" viewBox="0 0 32 32">
      <path
        d="M22.293 2.622c0-0.724-0.587-1.311-1.311-1.311s-1.311 0.587-1.311 1.311v1.312h-7.868v-1.312c0-0.724-0.587-1.311-1.311-1.311s-1.311 0.587-1.311 1.311v1.312h-2.623c-2.173 0-3.934 1.761-3.934 3.934v18.359c0 2.173 1.761 3.934 3.934 3.934h18.359c2.173 0 3.934-1.761 3.934-3.934v-18.359c0-2.173-1.761-3.934-3.934-3.934h-2.623v-1.312zM26.227 11.803h-20.982v-3.935c0-0.724 0.587-1.311 1.311-1.311h2.623v1.311c0 0.724 0.587 1.311 1.311 1.311s1.311-0.587 1.311-1.311v-1.311h7.868v1.311c0 0.724 0.587 1.311 1.311 1.311s1.311-0.587 1.311-1.311v-1.311h2.623c0.724 0 1.311 0.587 1.311 1.311v3.935zM5.245 14.425h20.982v11.801c0 0.724-0.587 1.311-1.311 1.311h-18.359c-0.724 0-1.311-0.587-1.311-1.311v-11.801z"
        :fill="color"
      ></path>
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconMenuCalendar',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
