<template>
  <div class="menu">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {},
};
</script>

<style lang="scss" scoped>
.menu {
  background: var(--menu-background-color);
  border: solid 1px var(--menu-border-color);
  border-radius: 6px;
  position: absolute;
  top: -2rem;
  left: -3rem;
  z-index: 12;
  font-size: 14px;
  line-height: 1.6;
  min-width: 200px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.menu-group {
  padding: 0.5rem 0;
}
.menu-group + .menu-group {
  border-top: solid 1px var(--menu-border-color);
}
.menu-title {
  font-size: 13px;
  opacity: 0.6;
  line-height: 1.2;
  padding: 0.25rem 1rem;
}
.menu-message {
  font-size: 13px;
  opacity: 0.6;
  padding: 0.25rem 1rem 0.5rem 1rem;
}
.menu-item {
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  text-decoration: none;
  color: var(--text-color);

  &:hover {
    background-color: var(--menu-background-hover);
  }

  &.clickable {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    display: grid;
    place-items: center;

    /deep/ svg {
      fill: var(--text-color);
    }
  }

  &.small {
    font-size: 13px;
  }
}
.menu-item-row {
  display: flex;
  align-items: center;
  width: 100%;
}
</style>
