<template>
  <div class="search" :class="{ focus: focus }">
    <input
      id="search-input"
      ref="searchInput"
      v-model="query"
      type="text"
      placeholder="Type to search or filter your notes"
      @input="onChange"
      @keydown.esc="onEsc($event)"
      @keydown="onKeydown($event)"
      @focus="onFocus"
      @blur="onBlur"
    />
    <IconSearch />
    <span class="shortcut-label">{{ metaKey }}K</span>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { isMacOs } from '@/utils';
import { EventBus, FOCUS_SEARCH } from '@/event-bus';
import { PAGE_SEARCH, PAGE_HOME } from '@/common/constants';
import IconSearch from '@/components/icons/Search';

const labelsArr = ['Tasks', 'Meetings', 'Bookmarks'];

export default {
  name: 'SearchInput',
  components: {
    IconSearch,
  },
  data: () => ({
    query: '',
    searchLabel: '',
    focus: false,
  }),
  computed: {
    metaKey() {
      return isMacOs ? '⌘' : 'ctrl';
    },
  },
  watch: {
    '$route.params.query'(query) {
      this.query = query;
    },
    $route(from) {
      this.updateSearchLabel(from);
    },
  },
  created() {
    this.query = this.$route.params.query;
    this.updateSearchLabel(this.$route);
    // set up event watcher
    EventBus.$on(FOCUS_SEARCH, this.focusInput);
  },
  methods: {
    focusInput() {
      this.$refs.searchInput.focus();
    },
    focusAndSelectInput() {
      this.$refs.searchInput.select();
    },
    onFocus() {
      this.focusAndSelectInput();
      // this.$emit('openMenu');
      this.focus = true;
    },
    onBlur() {
      this.focus = false;
    },
    onEsc(e) {
      this.$refs.searchInput.blur();
      e.preventDefault();
    },
    onKeydown(e) {
      // Ignore keypresses from special keys (arrows, tabs, enter etc)
      if (![9, 13, 16, 17, 18, 20, 27, 37, 38, 39, 40].includes(e.keyCode)) {
        // this.$emit('closeMenu');
        // when clearing the query, go home
        if (e.target.value.length === 0) {
          // only go home if we're not aleady home
          if (this.$router.currentRoute.name !== PAGE_HOME) {
            this.$router.push({ name: PAGE_HOME });
          }
        }
      }
    },
    clearLabel() {
      this.$router.push({ name: PAGE_HOME });
    },
    onChange: debounce(function () {
      this.updateUrl();
    }, 300),
    updateUrl() {
      const isSearchPage = this.$router.currentRoute.name === PAGE_SEARCH;
      const query = this.query.toLowerCase();
      if (!query) {
        this.$router.push({ name: PAGE_HOME });
      } else {
        // If we're on the search page, don't add a new history state
        if (isSearchPage) {
          this.$router.replace({ name: PAGE_SEARCH, params: { query } });
        } else {
          this.$router.push({ name: PAGE_SEARCH, params: { query } });
        }
      }
    },
    updateSearchLabel(route) {
      // Updates the label that appears inside the seach box
      if (labelsArr.includes(route.name)) {
        this.searchLabel = route.name;
      } else if (route.name === 'Tag') {
        this.searchLabel = `#${route.params.tag}`;
      } else if (route.name === 'Day') {
        this.searchLabel = route.params.date;
      } else {
        this.searchLabel = '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 100;
  background: var(--search-background-color);
  border: solid 1px var(--search-border-color);
  height: 44px;
  border-radius: 6px;
  padding: 0;
  display: flex;
  align-items: center;

  &.focus {
    border: solid 1px var(--search-focus-color);
  }

  input {
    font-size: 14px;
    width: 100%;
    border: none;
    color: var(--text-color);
    background: transparent;
    flex: 1;
    height: 40px;
    padding-right: 1rem;
    padding-left: 2.5rem;
    z-index: 1;

    &:focus {
      outline: none;
    }
  }
}
.icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  left: 12px;

  /deep/ svg {
    fill: var(--text-color);
  }
}
.shortcut-label {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 11px;
  font-style: italic;
  line-height: 22px;
  padding: 0 4px;
  border-radius: 4px;
  background: var(--search-shortcut-bg-color);
  color: var(--search-shortcut-text-color);
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07);
}
</style>
