<template>
  <div class="tab-bar">
    <div class="nav">
      <div class="nav-link">
        <router-link to="/">
          <IconMenuCalendar />
          <span class="label">Daily Notes</span>
        </router-link>
      </div>
      <div class="nav-link">
        <router-link to="/meetings">
          <IconMenuText />
          <span class="label">Meetings</span>
        </router-link>
      </div>
      <div class="nav-link">
        <router-link to="/bookmarks">
          <IconBookmark />
          <span class="label">Bookmarks</span>
        </router-link>
      </div>
      <div class="nav-link">
        <router-link to="/tasks">
          <IconTask />
          <span class="badge"><TaskBadge :get-count-from-store="true" /></span>
          <span class="label">Tasks</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import IconBookmark from '@/components/icons/Bookmark';
import IconMenuCalendar from '@/components/icons/MenuCalendar';
import IconMenuText from '@/components/icons/MenuText';
import IconTask from '@/components/icons/Task';
import TaskBadge from '@/components/TaskBadge';

export default {
  name: 'TabBar',
  components: {
    IconBookmark,
    IconMenuCalendar,
    IconMenuText,
    IconTask,
    TaskBadge,
  },
};
</script>

<style scoped lang="scss">
.tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 76px;
  padding-bottom: 1rem;
  background: var(--sidebar-background-color);
  z-index: 11;
  display: flex;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.nav {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  width: 100%;
}

.nav-link {
  position: relative;

  a {
    text-decoration: none;
    display: grid;
    place-items: center;

    &.router-link-exact-active {
      color: var(--sidebar-text-active-color);

      .icon {
        background: var(--sidebar-menu-bg-color);
      }
    }
  }

  .label {
    font-size: 10px;
    color: var(--text-color);
  }
  .icon {
    position: relative;
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    border-radius: 8px;

    /deep/ svg {
      width: 20px;
      height: 20px;
      fill: var(--sidebar-text-color);
    }
  }
}
.badge {
  position: absolute;
  left: 50%;
  top: 1.5px;
  margin-left: -7px;
}
</style>
