<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7071 5.71681C12.3166 5.32629 11.6834 5.32629 11.2929 5.71681L6.29289 10.7168C5.90237 11.1073 5.90237 11.7405 6.29289 12.131C6.68342 12.5216 7.31658 12.5216 7.70711 12.131L12 7.83813L16.2929 12.131C16.6834 12.5216 17.3166 12.5216 17.7071 12.131C18.0976 11.7405 18.0976 11.1073 17.7071 10.7168L12.7071 5.71681ZM17.7071 17.7168L12.7071 12.7168C12.3166 12.3263 11.6834 12.3263 11.2929 12.7168L6.29289 17.7168C5.90237 18.1073 5.90237 18.7405 6.29289 19.131C6.68342 19.5216 7.31658 19.5216 7.70711 19.131L12 14.8381L16.2929 19.131C16.6834 19.5216 17.3166 19.5216 17.7071 19.131C18.0976 18.7405 18.0976 18.1073 17.7071 17.7168Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconChevronDown',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
