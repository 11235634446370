<template>
  <div class="note">
    <div class="note-wrapper">
      <div
        class="note-content"
        :level="level"
        :data-id="data.id"
        :class="[{ active: popoutIsOpen, highlighted: shouldHighlight, xSelected: selected, lg: headerNote }, size]"
        @click="onNoteClick($event)"
      >
        <div v-if="showContext" class="context-link" content="See in context" @click="navigateToRootParent" v-tippy>
          <IconLevelUp />
        </div>
        <div v-if="canDrag" class="handle"><DragIcon /></div>
        <div ref="target" class="bullet" @click="togglePopout()">
          <NoteBullet :type="data.type" :completed="isCompletedTask" :marked="isMarked" />
        </div>
        <portal v-if="popoutIsOpen" to="body">
          <NoteMenu
            :open="popoutIsOpen"
            :type="data.type"
            :completed="isCompletedTask"
            :is-todo-later="isTodoLaterTask"
            :has-children="hasChildren"
            :hide-children="data.hideChildren"
            :marked="isMarked"
            :position="targetPosition"
            :is-fixed="zone === 'task'"
            @changeType="changeType"
            @completeTask="completeTask"
            @changeTaskGroup="changeTaskGroup"
            @deleteNote="forceDelete"
            @toggleChildren="toggleChildren"
            @toggleBookmark="toggleBookmark"
            @toggleMenu="togglePopout"
            @navigateToNote="navigateToNote"
            @navigateToRootParent="navigateToRootParent"
          />
        </portal>
        <ContentEditor
          :id="data.id"
          :type="data.type"
          :content="data.content"
          :is-completed-task="isCompletedTask"
          :zone="zone"
          @addNote="insertNewNote"
          @changeType="changeType"
          @deleteNote="requestDelete"
          @mergeNoteUp="mergeNoteUp"
          @moveToNextNote="moveToNextNote"
          @moveToPreviousNote="moveToPreviousNote"
          @navigateToNote="navigateToNote"
          @navigateToRootParent="navigateToRootParent"
          @stepOut="stepOut"
          @stepIn="stepIn"
          @toggleChildren="toggleChildren"
          @valueUpdated="valueUpdated"
        />
        <span v-if="debugUI" class="debug-pos">{{ data.position }} - {{ data.id }}</span>
      </div>
      <span v-if="hasChildren && hideChildren" class="children-count" @click="toggleChildren">
        <IconLevelDown /> {{ childNotes.length }} hidden
      </span>
      <div v-if="!hideChildren" class="children">
        <draggable
          :list="childNotes"
          group="list"
          ghost-class="ghost"
          class="dragArea"
          handle=".handle"
          @change="onDragChange"
        >
          <Note
            v-for="note in childNotes"
            :key="note.id"
            :data="note"
            :readonly="readonly"
            :force-open="forceOpen"
            :default-type="defaultType"
            :list-date="listDate"
            :notes="childNotes"
            :size="size"
            :level="level + 1"
          />
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ClickOutside from 'vue-click-outside';
import { EventBus, DOC_CLICK, SELECT_NOTES } from '@/event-bus';
import { PAGE_NOTE, PAGE_DAY } from '@/common/constants';
import { clearSelection, getCaretPosition, setCaretPosition, shiftCaretTo, moveCursorToEnd } from '@/utils/caret';
import { getPreviousSiblingNote, getPreviousAncestorNote, getRefPosition } from '@/utils/dom';
import {
  generateNoteId,
  addNoteTolist,
  deleteNote,
  changeNoteType,
  updateExistingNote,
  markTaskAsComplete,
  setTaskGroup,
  getInsertPosition,
  getDropPosition,
  getPreviousDomNote,
  getNextDomNote,
  selectNotesBetween,
} from '@/utils/notes';
import ContentEditor from '@/components/ContentEditor';
import DragIcon from '@/components/icons/Drag';
import IconLevelDown from '@/components/icons/LevelDown';
import IconLevelUp from '@/components/icons/LevelUp';
import NoteBullet from '@/components/NoteBullet';
import NoteMenu from '@/components/NoteMenu';

export default {
  name: 'Note',
  components: {
    ContentEditor,
    draggable,
    DragIcon,
    IconLevelDown,
    IconLevelUp,
    NoteBullet,
    NoteMenu,
  },
  directives: {
    ClickOutside,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    notes: {
      type: Array,
      default() {
        return [];
      },
    },
    listDate: {
      type: String,
      default: '',
    },
    defaultType: {
      type: String,
      default: 'note',
    },
    canDrag: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    childNotesOnly: {
      type: Boolean,
      default: false,
    },
    hideChildNotes: {
      type: Boolean,
      default: false,
    },
    showContext: {
      type: Boolean,
      default: false,
    },
    forceOpen: {
      type: Boolean,
      default: false,
    },
    headerNote: {
      type: Boolean,
      default: false,
    },
    zone: {
      type: String,
      default: 'note',
    },
    level: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md', 'lg'].indexOf(value) !== -1;
      },
    },
  },
  data: () => ({
    selected: false,
    shouldDelete: false,
    popoutIsOpen: false,
    debug: process.env.VUE_APP_DEBUG,
    targetPosition: {
      top: 0,
      left: 0,
    },
  }),
  computed: {
    debugUI() {
      return process.env.VUE_APP_DEBUG_UI == 'true';
    },
    domId() {
      return generateNoteId(this.data.id, this.zone);
    },
    childNotes() {
      return this.$store.getters['notes/childNotes'](this.data.id);
    },
    hasChildren() {
      return !!this.childNotes.length;
    },
    hideChildren() {
      // if forced open, don't hide
      if (this.forceOpen) return false;
      // if hide children is overridden
      if (this.hideChildNotes) return true;
      // else use internal note property
      return this.data.hideChildren;
    },
    isCompletedTask() {
      return this.data.type === 'task' && this.data.task.completed;
    },
    isTodoLaterTask() {
      return this.data.type === 'task' && this.data.task.group === 'later';
    },
    shouldHighlight() {
      const noteInUrl = this.$route.query.note;
      return noteInUrl && noteInUrl === this.data.id;
    },
    isMarked() {
      return this.data.marked;
    },
  },
  created() {
    EventBus.$on(DOC_CLICK, this.onDocClickEvent);
    EventBus.$on(SELECT_NOTES, this.onNotesSelectedEvent);
  },
  methods: {
    closePopout() {
      this.popoutIsOpen = false;
    },
    togglePopout() {
      this.updateTargetPosition();
      this.popoutIsOpen = !this.popoutIsOpen;
    },
    changeType(type) {
      changeNoteType(this.data, type);
      this.closePopout();
    },
    completeTask() {
      markTaskAsComplete(this.data);
      this.closePopout();
    },
    changeTaskGroup(group) {
      setTaskGroup(this.data, group);
      this.closePopout();
    },
    toggleChildren() {
      // if the child notes are force hidden, link to popup
      if (this.hideChildNotes) {
        this.navigateToNote();
      } else {
        const hideChildren = this.data.hideChildren ? false : true;
        this.updateNote({ hideChildren });
        this.closePopout();
      }
    },
    toggleBookmark() {
      const marked = this.data.marked ? false : true;
      this.updateNote({ marked });
      this.closePopout();
    },
    valueUpdated(value) {
      const content = value || '';
      this.updateNote({ content });
    },
    updateNote(change) {
      const updatedNote = {
        ...this.data,
        ...change,
      };
      updateExistingNote(updatedNote);
    },
    saveChanges() {
      updateExistingNote(this.data);
    },
    requestDelete() {
      deleteNote(this.domId, this.data.id, this.hasChildren, false);
    },
    forceDelete() {
      deleteNote(this.domId, this.data.id, this.hasChildren, true);
    },
    toggleSelectNote() {
      // set this note to selected
      this.selected = !this.selected;
    },
    selectNotesbetweenSelected() {
      // find notes between and select them too
      this.$nextTick(() => {
        selectNotesBetween();
        /* const notesBetween = getAllNotesBetween();
        if (notesBetween && notesBetween.length) {
          // publish array of notes between selected
          EventBus.$emit(SELECT_NOTES, notesBetween);
        } */
      });
    },
    onNotesSelectedEvent(noteIds) {
      // check if note is in array of noteIds
      if (noteIds && noteIds.length && noteIds.includes(this.data.id)) {
        // note was selected, select it
        this.selected = true;
      }
    },
    onNoteClick(e) {
      if (e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        clearSelection();
        // select this note, or unselect it
        this.toggleSelectNote();
        // select notes between if required
        this.selectNotesbetweenSelected();
      }
    },
    onDocClickEvent() {
      // watches global event bus for doc clicks
      if (this.selected) {
        this.selected = false;
      }
    },
    mergeNoteUp({ value = '' }) {
      // do nothing if it has children, too hard for now
      // else if there are not children, continue
      if (!this.hasChildren) {
        this.forceDelete();

        const currentNoteId = this.domId;
        const { previousDomId } = getPreviousDomNote(currentNoteId);

        if (previousDomId) {
          // add a space to string thats getting inserted
          const string = ` ${value}`;
          // Move cursor to previous note and capture position
          moveCursorToEnd(previousDomId);
          // Get the cursor pos
          const cursorPos = getCaretPosition(previousDomId);
          // insert text
          document.execCommand('insertHTML', false, string);
          // reset cursor pos to insert point
          this.$nextTick(() => {
            setCaretPosition(previousDomId, cursorPos);
          });
        }
      }
    },
    onDragChange($event) {
      const { element, newIndex } = $event.moved || $event.added || {};
      if (element) {
        const parentId = $event.added ? this.data.id : element.parent;
        // Dispatch edit to note
        this.$store.dispatch('notes/edit', {
          ...element,
          listDate: this.listDate || element.listDate,
          parent: parentId,
          position: getDropPosition(this.childNotes, newIndex),
        });
      }
    },
    async insertNewNote({ value = '', enterChild = false }) {
      if (this.readonly) return;

      const currentNote = this.data;
      // if the note is a childOnly note, force new notes into the child list
      if (this.childNotesOnly) {
        enterChild = true;
      }
      // Determine if we are planning to add a new note to the Child List
      // If this note has children AND they are not hidden
      const enterChildList = enterChild || (this.hasChildren && !currentNote.hideChildren);
      // If we are entering the children list this note has children AND they are hidden, then open the children
      if (currentNote.hideChildren && enterChildList) currentNote.hideChildren = false;
      // if there are children, we'll add the new note to that list instead of the same
      const parent = enterChildList ? currentNote.id : currentNote.parent;
      // Insert the Note after this ID unless there are children, then at the start
      const insertAt = enterChildList ? 'start' : currentNote.id;
      // Decide if we should use the Default Note Type passed in.
      // TODO: fix - this is a bit hacky
      const useType = parent ? false : this.defaultType;
      // Notes list to use
      const notesArr = enterChildList ? this.childNotes : this.notes;
      // Call Add Note util
      addNoteTolist(
        {
          parent,
          ...(value && { content: value }),
          ...(this.listDate && { listDate: this.listDate }),
          ...(useType && { type: this.defaultType }),
        },
        notesArr,
        insertAt
      );
    },
    stepIn() {
      if (this.readonly) return;

      const currentNoteId = this.domId;
      // Get cursor position, before moving
      const cursorPos = getCaretPosition(currentNoteId);
      // Find the previous Sibling Note ID in the DOM
      const previousNoteId = getPreviousSiblingNote(currentNoteId);

      // if a previous note can be found, continue
      if (previousNoteId) {
        // Get the parent note
        const parentNote = this.$store.getters['notes/noteById'](previousNoteId);
        // If this note has children AND they are hidden, then open the children
        if (parentNote.hideChildren) parentNote.hideChildren = false;

        // Get the notes children
        const parentNoteChildren = this.$store.getters['notes/childNotes'](previousNoteId);

        // Update Note
        /* eslint-disable vue/no-mutating-props */
        this.data.parent = previousNoteId;
        this.data.position = getInsertPosition('end', parentNoteChildren);
        /* eslint-enable vue/no-mutating-props */

        // Sync change
        this.saveChanges();

        // Ensure Cursor stays in position
        this.$nextTick(() => {
          setCaretPosition(currentNoteId, cursorPos);
        });
      }
    },
    stepOut() {
      const currentNoteId = this.domId;
      const noteParentId = this.data.parent;
      // Return early if this list is locked or the parent is already at the top level
      if (this.readonly || !noteParentId) return;

      // Get cursor position, before moving
      const cursorPos = getCaretPosition(currentNoteId);
      // Find the notes 2x ancestor in current DOM
      const previousAncestorId = getPreviousAncestorNote(currentNoteId);

      // If we're on the Note Deeplink page and the target parent is NUll, do nothing
      if (!previousAncestorId && this.$route.name === 'Note') {
        return;
      }

      // if the parent is not NUll, go get its children
      let parentParentChildren = [];
      if (previousAncestorId) {
        parentParentChildren = this.$store.getters['notes/childNotes'](previousAncestorId);
      } else if (this.listDate) {
        // parent is null, so assume top level list
        parentParentChildren = this.$store.getters['notes/notesByDate'](this.listDate);
      }

      // If we found a parent array, lets move it there.
      if (parentParentChildren && parentParentChildren.length) {
        // Update Note
        /* eslint-disable vue/no-mutating-props */
        this.data.parent = previousAncestorId;
        this.data.position = getInsertPosition(noteParentId, parentParentChildren);
        /* eslint-enable vue/no-mutating-props */

        // Sync change
        this.saveChanges();

        // Ensure Cursor stays in position
        this.$nextTick(() => {
          setCaretPosition(currentNoteId, cursorPos);
        });
      }
    },
    moveToNextNote({ currentNoteId, pos }) {
      // Find next Note Node and move cursor there
      const { nextDomId } = getNextDomNote(currentNoteId);
      if (nextDomId) {
        // move cursor to next note
        shiftCaretTo(nextDomId, pos, 'start');
      }
    },
    moveToPreviousNote({ currentNoteId, pos }) {
      // Find previous Note Node and move cursor there
      const { previousDomId } = getPreviousDomNote(currentNoteId);
      if (previousDomId) {
        // move cursor to prev note
        shiftCaretTo(previousDomId, pos, 'end');
      }
    },
    navigateToNote() {
      const currentId = this.data.id;
      // set noteId
      const noteId = currentId;
      // Navigate to it
      this.$router.push({ name: PAGE_NOTE, params: { noteId } });
      // Ensure Cursor moves to note
      this.$nextTick(() => {
        moveCursorToEnd(currentId);
      });
    },
    navigateToRootParent() {
      const currentId = this.data.id;
      // Get Note Tree
      const tree = this.$store.getters['noteTree/tree'];
      // Find this notes ancestors
      const ancestors = tree[currentId];
      // Get the Root Ancestor, if there is one
      const [lastAncestor] = ancestors.slice(-1);
      // set noteId
      const noteId = lastAncestor || currentId;
      // Navigate to it
      this.$router.push({ name: PAGE_NOTE, params: { noteId }, query: { note: currentId } });
      // Ensure Cursor moves to note
      this.$nextTick(() => {
        moveCursorToEnd(currentId);
      });
    },
    navigateToDay() {
      const date = this.data.listDate;
      // Navigate to it
      this.$router.push({ name: PAGE_DAY, params: { date }, query: { note: this.data.id } });
    },
    updateTargetPosition() {
      this.targetPosition = getRefPosition(this.$refs['target']);
    },
  },
};
</script>

<style scoped lang="scss">
.note {
  margin: 1px 0;
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 26px;

  .note:last-child {
    margin-bottom: 0.5rem;
  }

  .note .note {
    margin-bottom: 0;
  }
}
.note-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  min-width: 1px;
}
.note-content {
  display: flex;
  position: relative;
  padding: 3px 2px;

  &:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0);
    width: 100px;
    left: -100px;
    top: -1px;
    bottom: -1px;
  }

  &:hover .handle {
    opacity: 1;
  }

  &.active {
    background: var(--text-highlight-color);
  }

  &.highlighted {
    animation: fadeOut 8s ease-out;
  }

  &.xSelected,
  &.nSelected {
    background: var(--text-selected-color);
  }

  // Large font size
  /deep/ &.lg .content-editable {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  // Small font size
  /deep/ &.sm .content-editable {
    font-size: 14px;
  }
}
@media (min-width: $desktop) {
  .note-content:before {
    width: 180px;
    left: -180px;
  }
}
@keyframes fadeOut {
  0% {
    background: var(--text-highlight-color);
  }
  100% {
    background: transparent;
  }
}
.children-count {
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-size: 11px;
  color: var(--child-hidden-text);
  background: var(--child-hidden-background);
  height: 16px;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 1px;
  padding: 0 6px;
  cursor: pointer;

  &:hover {
    background: var(--child-hidden-background-hover);
  }

  .icon {
    margin-right: 6px;
  }

  /deep/ svg {
    fill: var(--child-hidden-text);
  }
}
.handle {
  position: absolute;
  top: 1px;
  left: -52px;
  height: 28px;
  width: 24px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 150ms linear;
  cursor: move;
  display: grid;
  place-items: center;

  .icon {
    width: 10px;
    height: 16px;
  }

  &:hover {
    background: var(--icon-drag-hover);
  }

  // small font size note
  .sm & {
    top: 0px;
  }
}

.bullet {
  display: grid;
  place-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
  height: 28px;
  width: 24px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  position: absolute;
  left: -26px;
  top: 1px;

  &:hover {
    background: var(--icon-drag-hover);
  }

  // large font size note
  .lg & {
    top: 4px;
  }

  // small font size note
  .sm & {
    top: 0px;
  }
}
.context-link {
  position: absolute;
  top: 1px;
  left: -52px;
  height: 28px;
  width: 24px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.35;
  display: grid;
  place-items: center;

  /deep/ .icon svg {
    fill: var(--text-color);
  }

  &:hover {
    opacity: 1;
    background: var(--icon-drag-hover);
  }
}
// debug styles
.debug-pos {
  font-size: 12px;
  color: #cacaca;
  font-family: 'Menlo', monospace;
  margin: 0 0 0 96px;
  white-space: nowrap;
  position: absolute;
  left: 100%;
  top: 7px;
}
</style>

<style lang="scss">
.note.ghost {
  background: var(--drop-target-background-color);
  height: 4px;

  // hide content
  > * {
    display: none;
  }
}
</style>
