<template>
  <NotesGroup>
    <DayList v-for="day in days" :key="day.key" :day="day" />
  </NotesGroup>
</template>

<script>
import dayjs from 'dayjs';

import DayList from '@/components/DayList';
import NotesGroup from '@/components/NotesGroup';

export default {
  name: 'Week',
  components: {
    DayList,
    NotesGroup,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    source: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    days: [],
  }),
  watch: {
    source: {
      handler(newVal, oldVal) {
        if (newVal.weekLength !== oldVal.weekLength) {
          this.updateDays();
        }
      },
      deep: true,
    },
  },
  created() {
    this.updateDays();
  },
  methods: {
    updateDays() {
      this.days = this.buildDays();
    },
    buildDays() {
      const daysInWeek = this.source.weekLength;
      return [...Array(daysInWeek)].map((_item, i) => {
        return this.getDay(i);
      });
    },
    getDay(offset) {
      const endOfWeek = this.source.endOfWeek;
      const thisDay = dayjs(endOfWeek).subtract(offset, 'day');
      const thisYear = dayjs().endOf('year');
      const diffYear = thisDay.diff(thisYear, 'year');
      const prettyDateFormat = diffYear < 0 ? 'D MMM YYYY' : 'D MMM';

      return {
        index: offset,
        key: thisDay.format('YYYY-MM-DD'),
        label: thisDay.calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          lastDay: '[Yesterday]',
          lastWeek: 'dddd',
          sameElse: 'dddd',
        }),
        date: thisDay.format(),
        prettyDate: thisDay.format(prettyDateFormat),
        isHero: offset === 0 && this.source.page === 0,
      };
    },
  },
};
</script>
