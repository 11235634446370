<template>
  <button class="button" @click="onClick()">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  background: $color_black;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 0 1.5rem;
  line-height: 40px;
  border-radius: 20px;
  letter-spacing: 0.04em;

  &:hover {
    background: #111;
  }
}
</style>
