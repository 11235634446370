import { buildNoteTree } from '@/utils';

const DEBUG = process.env.VUE_APP_DEBUG;

/**
 * Generates a reverse tree of the Nodes relationaships
 * Example
 * nodeid124: [firstParent, nextParent, nextParent, rootParent]
 */

const state = {
  tree: {},
};

const getters = {
  tree: (state) => state.tree,
};

const mutations = {
  set: (state, tree) => {
    state.tree = tree;
  },
};

const actions = {
  recalculate({ commit, rootState }) {
    if (DEBUG) console.log('--- recalculate tree');
    const tree = buildNoteTree(rootState.notes.notes);
    commit('set', tree);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
