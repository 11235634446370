<template>
  <SearchResults />
</template>

<script>
import SearchResults from '@/components/SearchResults';

export default {
  name: 'Search',
  components: {
    SearchResults,
  },
};
</script>
