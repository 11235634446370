<template>
  <div class="dayList">
    <NotesLayout
      :title="day.label"
      :preTitle="day.prettyDate"
      :key="day.key"
      :listDate="day.key"
      :showAddNote="day.isHero"
      :showEmptyMessage="!day.isHero"
      :notes="notes"
      :isHero="day.isHero"
    />
  </div>
</template>

<script>
import NotesLayout from '@/components/NotesLayout';

export default {
  name: 'DayList',
  components: {
    NotesLayout,
  },
  props: {
    day: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    notes: {
      get() {
        return this.$store.getters['notes/notesByDate'](this.day.key);
      },
      set() {},
    },
  },
  methods: {},
};
</script>
