<template>
  <DailyNotes />
</template>

<script>
import DailyNotes from '@/components/DailyNotes';

export default {
  name: 'Scroller',
  components: {
    DailyNotes,
  },
};
</script>
