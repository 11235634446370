<template>
  <div v-hotkey="keymap" class="app" @click="onAppClick">
    <Welcome v-if="welcomeOpen" />
    <RefreshButton v-if="updateExists" @requestRefresh="refreshApp" />
    <Header />
    <Sidebar v-if="!isMobileDevice" />
    <TabBar v-if="isMobileDevice" />
    <notifications position="bottom center" :duration="2000" classes="td-notification" />
    <DragSelect>
      <router-view class="app_view_modal" name="modal" />
      <router-view />
    </DragSelect>
    <portal-target name="body" />
  </div>
</template>

<script>
import { initDb } from '@/db';
import { isMobile } from '@/utils';
import { copySelectedTextToClipboard } from '@/utils/copyPaste';
import { selectAllNotes } from '@/utils/notes';
import { EventBus, DOC_CLICK, FOCUS_SEARCH, OPEN_KEYBOARD_SHORTCUTS } from '@/event-bus';
import DragSelect from '@/components/DragSelect';
import RefreshButton from '@/components/RefreshButton';
import Header from '@/components/Header';
import Sidebar from '@/components/Sidebar';
import TabBar from '@/components/TabBar';
import Welcome from '@/components/Welcome';

export default {
  name: 'App',
  components: {
    DragSelect,
    RefreshButton,
    Header,
    Sidebar,
    TabBar,
    Welcome,
  },
  data: () => ({
    worker: null,
    refreshing: false,
    updateExists: false,
  }),
  computed: {
    welcomeOpen() {
      return this.$store.getters.getConfig.welcomeOpen;
    },
    keymap() {
      return {
        'command+k': this.focusSearch,
        'ctrl+k': this.focusSearch,
        'command+/': this.openKeyboardShortcuts,
        'ctrl+/': this.openKeyboardShortcuts,
        'command+c': this.copyGobalText,
        'ctrl+c': this.copyGobalText,
        'command+a': this.selectAllText,
        'ctrl+a': this.selectAllText,
      };
    },
    isMobileDevice() {
      return isMobile();
    },
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'Tueday';
    },
  },
  beforeCreate() {
    // initialise Database
    initDb();
    // Fetch settings from DB
    this.$store.dispatch('fetchSettings');
    // Fetch notes frmo DB
    this.$store.dispatch('notes/fetchNotes');
    // Fetch tags from DB
    this.$store.dispatch('tags/fetch');
  },
  created() {
    // Listen for swUpdated event and display refresh button as required.
    document.addEventListener('swUpdated', this.showRefreshButton, { once: true });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    focusSearch() {
      // Publish event to EventBus
      EventBus.$emit(FOCUS_SEARCH, true);
    },
    openKeyboardShortcuts() {
      // Publish event to EventBus
      EventBus.$emit(OPEN_KEYBOARD_SHORTCUTS, true);
    },
    onAppClick() {
      // Publish event to EventBus
      EventBus.$emit(DOC_CLICK, true);
    },
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    showRefreshButton(e) {
      // Display a button inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      if (e.detail) {
        this.worker = e.detail;
        this.updateExists = true;
      }
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.worker || !this.worker.waiting) return;
      this.worker.waiting.postMessage('skipWaiting');
    },
    copyGobalText() {
      copySelectedTextToClipboard();
    },
    selectAllText() {
      selectAllNotes();
    },
  },
};
</script>

<style scoped lang="scss">
.app {
  padding: $header_height 0;
}
</style>
