<template>
  <div class="listing nl">
    <NotesGroup>
      <DayList :day="day" />
    </NotesGroup>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DayList from '@/components/DayList';
import NotesGroup from '@/components/NotesGroup';

export default {
  name: 'NoteDay',
  components: {
    DayList,
    NotesGroup,
  },
  computed: {
    day() {
      const date = this.$route.params.date;
      const thisDay = dayjs(date);
      return {
        index: 0,
        key: date,
        label: thisDay.format('dddd'),
        prettyDate: thisDay.format('D MMM YYYY'),
      };
    },
  },
};
</script>
