import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import TaskBoard from '@/views/TaskBoard';
import Bookmarks from '@/views/Bookmarks';
import Tag from '@/views/Tag';
import Search from '@/views/Search';
import Meetings from '@/views/Meetings';
import NoteDeeplink from '@/views/NoteDeeplink';
import NoteDeeplinkModal from '@/views/NoteDeeplinkModal';
import NoteDay from '@/views/NoteDay';
import Scroller from '@/views/Scroller';
import {
  PAGE_HOME,
  PAGE_TASKS,
  PAGE_TAG,
  PAGE_SEARCH,
  PAGE_ABOUT,
  PAGE_BOOKMARKS,
  PAGE_MEETINGS,
  PAGE_NOTE,
  PAGE_DAY,
} from '@/common/constants';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: PAGE_HOME,
    meta: {
      title: 'Tueday',
      modalView: true,
    },
    component: Home,
  },
  {
    path: '/tasks',
    name: PAGE_TASKS,
    meta: {
      title: 'Your tasks | Tueday',
      modalView: true,
    },
    component: TaskBoard,
  },
  {
    path: '/meetings',
    name: PAGE_MEETINGS,
    meta: {
      title: 'Meeting notes | Tueday',
      modalView: true,
    },
    component: Meetings,
  },
  {
    path: '/bookmarks',
    name: PAGE_BOOKMARKS,
    meta: {
      title: 'Bookmarked Notes | Tueday',
      modalView: true,
    },
    component: Bookmarks,
  },
  {
    path: '/note/:noteId',
    name: PAGE_NOTE,
    beforeEnter: (to, from, next) => {
      const modalView = from.matched.some((view) => view.meta && view.meta.modalView);
      if (!modalView) {
        to.matched[0].components = {
          default: NoteDeeplink,
          modal: false,
        };
      } else {
        if (from.matched.length > 1) {
          const childrenView = from.matched.slice(1, from.matched.length);
          for (let view of childrenView) {
            to.matched.push(view);
          }
        }
        if (to.matched[0].components) {
          to.matched[0].components.default = from.matched[0].components.default;
          to.matched[0].components.modal = NoteDeeplinkModal;
        }
      }
      next();
    },
    component: NoteDeeplink,
  },
  {
    path: '/day/:date',
    name: PAGE_DAY,
    meta: {
      title: 'Notes | Tueday',
      modalView: true,
    },
    component: NoteDay,
  },
  {
    path: '/tag/:tag',
    name: PAGE_TAG,
    meta: {
      title: 'Search | Tueday',
      modalView: true,
    },
    component: Tag,
  },
  {
    path: '/search/:query',
    name: PAGE_SEARCH,
    meta: {
      title: 'Search | Tueday',
      modalView: true,
    },
    component: Search,
  },
  {
    path: '/old-daily-notes',
    name: 'Scroller',
    meta: {
      title: 'Scroller Test',
      modalView: true,
    },
    component: Scroller,
  },
  {
    path: '/about',
    name: PAGE_ABOUT,
    meta: {
      title: 'About | Tueday',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
  },
  {
    path: '/about/privacy',
    name: 'Privacy',
    meta: {
      title: 'Privacy Policy | Tueday',
    },
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy'),
  },
  {
    path: '/about/terms',
    name: 'Terms',
    meta: {
      title: 'Terms of use | Tueday',
    },
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Don't scroll top top if opening modal
    if (to.name === 'Note') return;
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
