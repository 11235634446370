<template>
  <div>
    <draggable
      :list="tasks"
      group="taskslist"
      ghost-class="ghost"
      class="dragArea"
      handle=".handle"
      :disabled="!isDraggable"
      @change="onDragChange"
    >
      <Note
        v-for="note in tasks"
        :key="note.id"
        :data="note"
        :can-drag="isDraggable"
        :notes="tasks"
        :hide-child-notes="hideChildNotes"
        :size="size"
        :zone="zone"
        default-type="task"
      />
    </draggable>
    <Empty v-if="!tasks || !tasks.length">
      {{ emptyMessage }}
    </Empty>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Empty from '@/components/Empty';
import Note from '@/components/Note';
import { markTaskAsComplete, getTaskDropPosition } from '@/utils/notes';

export default {
  name: 'TaskListGroup',
  components: {
    draggable,
    Empty,
    Note,
  },
  props: {
    groupName: {
      type: String,
      default: null,
    },
    size: {
      type: String,
    },
    zone: {
      type: String,
      default: 'task',
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    emptyMessage: {
      type: String,
      default: 'Tasks found in your notes will appear here',
    },
    hideChildNotes: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDraggable() {
      return !this.readonly;
    },
  },
  methods: {
    onDragChange($event) {
      const { element, newIndex } = $event.moved || $event.added || {};
      if (element) {
        const isCompletedList = this.groupName === 'completed';
        // Id is completed, mark as complete
        if (isCompletedList) {
          markTaskAsComplete(element);
        } else {
          const dropPosition = getTaskDropPosition(this.tasks, newIndex);
          // Dispatch edit to note
          this.$store.dispatch('notes/edit', {
            ...element,
            task: {
              ...element.task,
              position: dropPosition,
              group: this.groupName,
              completed: false,
            },
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dragArea {
  min-height: 2rem;
  z-index: 2;
}
.empty {
  margin-top: -2.5rem;
}
</style>
