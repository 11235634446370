<template>
  <Modal>
    <div class="back-button" @click="goBack"><IconArrowLeft />Close</div>
    <div class="container">
      <NotesLayout
        :subTitle="subTitle"
        :headerNote="true"
        :childNotesOnly="true"
        :hideContext="true"
        :forceOpen="true"
        :listDate="listDate"
        :showAddNote="false"
        :showEmptyMessage="false"
        :notes="notes"
      />
    </div>
  </Modal>
</template>

<script>
import dayjs from 'dayjs';
import IconArrowLeft from '@/components/icons/ArrowLeft';
import Modal from '@/components/Modal';
import NotesLayout from '@/components/NotesLayout';

export default {
  name: 'NoteDeeplinkModal',
  components: {
    IconArrowLeft,
    Modal,
    NotesLayout,
  },
  computed: {
    notes() {
      const noteId = this.$route.params.noteId;
      const note = this.$store.getters['notes/noteById'](noteId);
      return note ? [note] : [];
    },
    subTitle() {
      const note = this.notes[0];
      if (note.listDate === 'task') return null;
      return this.notes.length ? dayjs(note.listDate).format('D MMM YYYY') : '';
    },
    listDate() {
      return this.notes[0] && this.notes[0].listDate;
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;
}
.back-button {
  position: absolute;
  line-height: 1;
  top: 1rem;
  left: 1rem;
  font-size: 14px;
  opacity: 0.7;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    display: grid;
    place-items: center;

    /deep/ svg {
      fill: var(--text-color);
    }
  }
}
</style>
