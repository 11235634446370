<template>
  <div class="empty">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Empty',
};
</script>

<style scoped lang="scss">
.empty {
  padding: 0.5rem 0 0.5rem 0.25rem;
  font-size: 1rem;
  opacity: 0.5;
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 8px;
    margin-left: 4px;

    /deep/ svg {
      width: 16px;
      height: 16px;
      fill: var(--text-color);
    }
  }
}
</style>
