<template>
  <div class="listing nl">
    <NotesGroup>
      <div v-for="(notes, key, index) in groupedNotes" :key="key">
        <NotesLayout
          :title="getTitle(index)"
          :subTitle="formatDate(key)"
          :listDate="key"
          :readonly="true"
          :compact="true"
          :showAddNote="false"
          :showEmptyMessageAddButton="false"
          :showEmptyMessage="true"
          :notes="notes"
          type="event"
          emptyMessage="No meeting notes here yet."
        />
      </div>
      <NotesLayout
        v-if="!groupedNotes || Object.keys(groupedNotes).length === 0"
        :title="getTitle(0)"
        :readonly="true"
        :compact="true"
        :showAddNote="false"
        :showEmptyMessageAddButton="false"
        :showEmptyMessage="true"
        :notes="[]"
        type="event"
        emptyMessage="No meeting notes here yet."
      />
    </NotesGroup>
  </div>
</template>

<script>
import { removeDuplicateNotes, groupNotesBy } from '@/utils/notes';
import NotesGroup from '@/components/NotesGroup';
import NotesLayout from '@/components/NotesLayout';
import dayjs from 'dayjs';

export default {
  name: 'Meetings',
  components: {
    NotesGroup,
    NotesLayout,
  },
  computed: {
    groupedNotes() {
      const notes = this.$store.getters['notes/notesByType']('event');
      // remove any duplicate Notes - this happens when a child task has a parent task and it appears twice.
      // This will only show the Parent task and assumes the child is therefore shown.
      const unique = removeDuplicateNotes(notes);
      // Sort by created date
      const uniqueSorted = unique.sort((a, b) => b.listDate.localeCompare(a.listDate));
      // group notes by date
      return groupNotesBy(uniqueSorted, 'listDate');
    },
  },
  methods: {
    formatDate(date) {
      if (date && date != 'null') {
        return dayjs(date).format('D MMM YYYY');
      }
      return null;
    },
    getTitle(index) {
      return index === 0 ? 'Meetings' : '';
    },
  },
};
</script>
