const replaceNewLines = (text, replace = ' ') => {
  return text.replace(/[\r\n\u0085\u2028\u2029]+/g, replace);
};

const splitStringOnCursor = (string, cursorPos) => {
  const before = string.slice(0, cursorPos).trim();
  const after = string.slice(cursorPos).trim();
  return [before, after];
};

// Text formatter methods
// ----------------------

// Highlight substring in string
const highlightSubStringInString = (string, subString) => {
  if (subString) {
    const regex = new RegExp('(' + subString + ')', 'gi');
    return string.replace(regex, '<span class="highlight">$1</span>');
  }
  return string;
};

const linkifyHashTag = (string) => {
  return string.replace(/#([\w]+)/g, '<a class="link hashtag" href="/tag/$1">$&</a>');
};

const linkifyMentions = (string) => {
  return string.replace(/@([\w]+)/g, '<a class="link mention" href="/search/@$1">$&</a>');
};

const linkifyString = (string) => {
  var regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return string.replace(regex, '<a class="link" href="$1" target="_blank">$1</a>');
};

const parseTagsFromString = (string) => {
  const tags = string.split(/\s/gm).filter((s) => s.startsWith('#'));
  return tags.map((tag) => tag.split('#')[1].toLowerCase());
};

const isSlashCommand = (value) => {
  /*
  const regex = /\/(\w+)/;
  const found = value.match(regex);
  const foundCommand = found ? found[0] : false;
  if (foundCommand && foundCommand.startsWith('/')) {
    switch (foundCommand) {
  */
  const cleanValue = value.trim();
  if (cleanValue.startsWith('/')) {
    switch (cleanValue) {
      case '/task':
      case '/t':
        return { command: 'changeType', type: 'task' };
      case '/note':
      case '/n':
        return { command: 'changeType', type: 'note' };
      case '/meeting':
      case '/m':
        return { command: 'changeType', type: 'event' };
      default:
        return false;
    }
  }
  return false;
};

export {
  splitStringOnCursor,
  highlightSubStringInString,
  linkifyString,
  linkifyHashTag,
  linkifyMentions,
  isSlashCommand,
  replaceNewLines,
  parseTagsFromString,
};
