<template>
  <div class="listing nl">
    <NotesGroup v-for="week in weeks" :key="week.key">
      <DayList v-for="day in week.days" :key="day.key" :day="day" />
      <ScrollTrigger :page="week.page" @triggerIntersected="loadMore" />
    </NotesGroup>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import NotesGroup from '@/components/NotesGroup';
import DayList from '@/components/DayList';
import ScrollTrigger from '@/components/ScrollTrigger';

dayjs.extend(calendar);

const RESET = 0;
const WEEK_LEN = 6;

export default {
  name: 'Days',
  components: {
    DayList,
    NotesGroup,
    ScrollTrigger,
  },
  data: () => ({
    today: null,
    days: [],
    weeks: [],
    page: 0,
    dayOffset: 0,
  }),
  watch: {
    today(newVal, oldVal) {
      // Checking for date changes
      if (newVal !== oldVal) {
        this.resetToday();
      }
    },
  },
  mounted() {
    const refreshInterval = 10 * 1000; // 10 minutes
    this.updateToday();
    setInterval(this.updateToday, refreshInterval);
  },
  methods: {
    updateToday() {
      this.today = dayjs().format('YYYY-MM-DD');
    },
    buildDays(dayLen, offset) {
      const days = [];
      for (let i = 0; i <= dayLen; i += 1) {
        const dayNum = i + offset;
        const thisYear = dayjs().endOf('year');
        const thisDay = dayjs().subtract(dayNum, 'day');
        const diffYear = thisDay.diff(thisYear, 'year');
        const prettyDateFormat = diffYear < 0 ? 'D MMM YYYY' : 'D MMM';

        days.push({
          index: dayNum,
          key: thisDay.format('YYYY-MM-DD'),
          label: thisDay.calendar(null, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: 'dddd',
            sameElse: 'dddd',
          }),
          date: thisDay.format(),
          prettyDate: thisDay.format(prettyDateFormat),
        });
      }
      return days;
    },
    loadMore(pageTrigger) {
      // Only trigger if the next page has note loaded
      if (pageTrigger == this.page) {
        this.page = this.page + 1;
        this.addWeek(this.page);
      }
    },
    getWeekObj(weekNum) {
      const daysInWeek = weekNum > 0 ? WEEK_LEN : this.getWeekLength();
      const offset = this.dayOffset + weekNum;
      const days = this.buildDays(daysInWeek, offset);
      // increment offset
      this.dayOffset = this.dayOffset + daysInWeek;
      // return object
      return {
        key: `week-${weekNum}`,
        page: weekNum,
        days,
      };
    },
    addWeek(weekNum) {
      weekNum = weekNum || RESET;
      this.weeks.push(this.getWeekObj(weekNum));
    },
    resetToday() {
      window.scrollTo(0, 0);
      this.dayOffset = RESET;
      this.page = RESET;
      const firstWeek = this.getWeekObj(RESET);
      this.weeks = [firstWeek];
    },
    getWeekLength() {
      const daysInThisWeek = Number(dayjs().format('d'));
      return daysInThisWeek > 3 ? daysInThisWeek - 1 : WEEK_LEN + daysInThisWeek;
    },
  },
};
</script>
