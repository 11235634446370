import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { getTags, storeTag, deleteTag } from '@/db';
import { POSITION_INCREMENT } from '@/common/constants';

const state = {
  tags: [],
};

const getters = {
  tags: (state) => state.tags,
  tagIds: (state) => state.tags.reduce((accumulator, tag) => accumulator.concat(tag.id), []),
  tagById: (state) => (id) => state.tags.find((tag) => tag.id === id),
  childTags:
    (state) =>
    (parent = null) => {
      const tags = state.tags.filter((tag) => tag.parent === parent);
      return tags.sort((a, b) => a.position - b.position);
    },
};

const mutations = {
  set: (state, tags) => {
    state.tags = tags;
  },
  add(state, tag) {
    state.tags.push(tag);
  },
  edit(state, tag) {
    const index = state.tags.findIndex((x) => x.id === tag.id);
    state.tags.splice(index, 1, tag);
  },
  remove(state, tagId) {
    const index = state.tags.findIndex((x) => x.id === tagId);
    state.tags.splice(index, 1);
  },
};

const actions = {
  async fetch({ commit }) {
    // Fetch tags
    const tags = await getTags();
    if (!isEmpty(tags)) {
      commit('set', tags);
    }
  },
  add({ commit, getters }, tag) {
    if (tag && tag.name) {
      // get existing tags to calc position offset
      const existingTags = getters.childTags();
      // get date
      const now = dayjs();
      const nowStr = now.format();
      const newTag = {
        id: tag.name,
        parent: null,
        createdDate: nowStr,
        modifiedDate: nowStr,
        position: existingTags.length * POSITION_INCREMENT,
        ...tag,
      };
      // Commite to State
      commit('add', newTag);
      // Store in DB
      storeTag(newTag);
    }
  },
  edit({ commit }, tag) {
    const nowStr = dayjs().format();
    const updatedTag = {
      ...tag,
      modifiedDate: nowStr,
    };
    // Update in State
    commit('edit', updatedTag);
    // Store in DB
    storeTag(updatedTag);
  },
  batchAdd({ dispatch }, ids) {
    ids.forEach((tagId) => {
      dispatch('add', { name: tagId });
    });
  },
  remove({ commit }, tagId) {
    // Remove from State
    commit('remove', tagId);
    // Delete from DB
    deleteTag(tagId);
  },
  batchRemove({ dispatch }, ids) {
    ids.forEach((tagId) => {
      dispatch('remove', tagId);
    });
  },
  update({ dispatch, getters, rootGetters }) {
    const noteTags = rootGetters['notes/noteTags'];
    const existingTags = getters.tagIds;
    const newTags = noteTags.filter((x) => !existingTags.includes(x));
    const removedTags = existingTags.filter((x) => !noteTags.includes(x));
    // dispatch events to update tags
    dispatch('batchAdd', newTags);
    dispatch('batchRemove', removedTags);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
