<template>
  <div class="header">
    <div class="brand" :class="{ sidebarOpen: sidebarOpen }">
      <div v-if="!isMobileDevice" class="menu-toggle" @click="toggleSidebar()">
        <IconMenu />
      </div>
      <Logo />
    </div>
    <div class="search-box">
      <SearchInput />
    </div>
    <div class="settings">
      <div v-tippy class="settings-icon" content="Options" @click="openSettingsMenu">
        <IconSettings />
      </div>
      <SettingsMenu :open="showSettingsMenu" @toggleOpen="toggleSettingsMenu" />
    </div>
  </div>
</template>

<script>
import { isMobile } from '@/utils';
import IconSettings from '@/components/icons/Settings';
import IconMenu from '@/components/icons/Menu';
import Logo from '@/components/Logo';
import SettingsMenu from '@/components/SettingsMenu';
import SearchInput from '@/components/SearchInput';

export default {
  name: 'Header',
  components: {
    IconSettings,
    IconMenu,
    Logo,
    SettingsMenu,
    SearchInput,
  },
  data: () => ({
    showSettingsMenu: false,
  }),
  computed: {
    sidebarOpen() {
      return this.$store.getters.getConfig.sidebarOpen;
    },
    isMobileDevice() {
      return isMobile();
    },
  },
  methods: {
    toggleSidebar() {
      const value = !this.sidebarOpen;
      this.$store.dispatch('updateConfig', { sidebarOpen: value });
    },
    openSettingsMenu() {
      this.showSettingsMenu = true;
      this.$gtag.event('OpenSettings', {
        event_category: 'Settings',
      });
    },
    toggleSettingsMenu() {
      this.showSettingsMenu = !this.showSettingsMenu;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: $header_height + 14px;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: $zIndexHeader;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr minmax(200px, 558px) 1fr;
}

@media (min-width: $tablet) {
  .header {
    background: var(--app-background-color);
    padding: 0 1.5rem;
  }
}

@media (min-width: $desktop) {
  .header {
    background: transparent;
  }
}

.search-box {
  width: 100%;
  display: none;
  position: relative;
  background: var(--app-background-color);
}
@media (min-width: $tablet) {
  .search-box {
    display: flex;
    align-items: center;
  }
}
.brand {
  display: flex;
  align-items: center;
  z-index: 11;
  padding-right: 1rem;
  padding-left: 0.5rem;
}
@media (min-width: $tablet) {
  .brand {
    padding-left: 0;

    &.sidebarOpen {
      width: $sidebar_width;
    }
  }
}
.menu-toggle {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  z-index: 12;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 6px;
  background: var(--sidebar-background-color);

  &:hover {
    background: var(--sidebar-menu-bg-color);
  }

  .icon {
    position: relative;

    /deep/ svg {
      width: 20px;
      height: 20px;
      fill: var(--sidebar-text-color);
    }
  }
}
.settings {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 1rem;
  margin-right: -0.5rem;
}
@media (min-width: $tablet) {
  .settings {
    margin-right: -1rem;
  }
}
.settings-icon {
  height: 40px;
  width: 40px;
  display: grid;
  place-items: center;
  cursor: pointer;

  .icon {
    width: 18px;
    height: 18px;
  }

  /deep/ svg {
    fill: var(--text-color);
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
