<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconChecklist',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
