<template>
  <div class="notes" :class="{ compact: compact, hero: isHero }">
    <div class="titles">
      <div v-if="preTitle" class="preTitle">{{ preTitle }}</div>
      <h2 v-if="title" class="title">{{ title }}</h2>
      <div v-if="subTitle" class="subTitle" @click="navigateToDay">
        <span>{{ subTitle }}</span>
      </div>
    </div>
    <div class="content">
      <AddNote v-if="showAddNote" :label="addNoteLabel" @addNote="onAddNote" />
      <div v-if="showEmptyMessage && !notes.length" class="message">
        {{ emptyMessage }} <span v-if="showEmptyMessageAddButton" @click="onAddNote">Add one.</span>
      </div>
      <draggable
        :list="notes"
        group="list"
        ghost-class="ghost"
        class="dragArea"
        handle=".handle"
        :disabled="!isDraggable"
        @change="onDragChange"
      >
        <Note
          v-for="note in notes"
          :key="note.id"
          :data="note"
          :header-note="headerNote"
          :child-notes-only="childNotesOnly"
          :readonly="readonly"
          :force-open="forceOpen"
          :list-date="listDate"
          :can-drag="isDraggable"
          :default-type="type"
          :notes="notes"
          :show-context="showContextButton(note)"
        />
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { PAGE_DAY } from '@/common/constants';
import AddNote from '@/components/AddNote';
import Note from '@/components/Note';
import { addNoteTolist, getDropPosition } from '@/utils/notes';

export default {
  name: 'NotesLayout',
  components: {
    AddNote,
    draggable,
    Note,
  },
  props: {
    title: {
      type: String,
    },
    preTitle: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    addNoteLabel: {
      type: String,
      default: 'Add a note',
    },
    showAddNote: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    headerNote: {
      type: Boolean,
      default: false,
    },
    childNotesOnly: {
      type: Boolean,
      default: false,
    },
    showEmptyMessage: {
      type: Boolean,
      default: true,
    },
    showEmptyMessageAddButton: {
      type: Boolean,
      default: true,
    },
    emptyMessage: {
      type: String,
      default: 'No notes added this day.',
    },
    showChildren: {
      type: Boolean,
      default: true,
    },
    showContext: {
      type: Boolean,
      default: false,
    },
    hideContext: {
      type: Boolean,
      default: false,
    },
    forceOpen: {
      type: Boolean,
      default: false,
    },
    listDate: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'note',
    },
    isHero: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    notes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isDraggable() {
      return !this.readonly && !this.childNotesOnly;
    },
  },
  methods: {
    showContextButton(note) {
      if (this.hideContext) return false;
      return !!note.parent || this.showContext;
    },
    async onAddNote() {
      if (this.readonly) return;
      addNoteTolist(
        {
          type: this.type,
          ...(this.listDate && { listDate: this.listDate }),
        },
        this.notes
      );
      // Log event
      this.$gtag.event('AddNote', {
        event_category: 'CTA',
        event_label: this.isHero ? 'Today' : 'Other',
      });
    },
    onDragChange($event) {
      const { element, newIndex } = $event.moved || $event.added || {};
      if (element) {
        // Dispatch edit to note
        this.$store.dispatch('notes/edit', {
          ...element,
          listDate: this.listDate || element.listDate,
          parent: this.parentId || null,
          position: getDropPosition(this.notes, newIndex),
        });
      }
    },
    navigateToDay() {
      const date = this.listDate;
      this.$router.push({ name: PAGE_DAY, params: { date } });
    },
  },
};
</script>

<style scoped lang="scss">
.notes {
  max-width: 558px;
  width: 100%;
  min-height: 8rem;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.compact {
    margin-bottom: 1rem;
    min-height: 0px;
  }

  > * {
    width: 100%;
  }

  &.hero {
    min-height: 55vh;
  }
}
.titles {
  display: flex;
  flex-direction: column;
}
.title {
  max-width: 100%;
}
.preTitle {
  font-size: 13px;
  opacity: 0.8;
  font-weight: 400;
  margin-bottom: -4px;
}
.subTitle {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0.75rem;
  margin-top: 1rem;
  align-self: flex-start;
  width: auto;
  background: var(--subtitle-background-color);
  border-radius: 4px;
  padding: 0 6px;

  &:hover {
    color: $color_text_dark;
    background: $color_primary;
    cursor: pointer;
  }
}
.message {
  width: 100%;
  opacity: 0.5;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
