<template>
  <transition name="modal-fade">
    <div v-scroll-lock="showContent" class="modal-box">
      <div class="modal-scroller">
        <transition name="slide-up" @after-leave="onClose">
          <div v-if="showContent" class="modal">
            <slot />
          </div>
        </transition>
      </div>
      <div class="overlay" @click.stop.self="onClose" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  data: () => ({
    showContent: false,
  }),
  mounted() {
    this.showContent = true;
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    toggleContent() {
      this.showContent = !this.showContent;
    },
    onClose() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
    triggerClose() {
      this.showContent = false;
    },
    handleKeydown(e) {
      if (e.key == 'Escape') {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $zIndexModal;
  overflow-y: auto;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--modal-overlay-background-color);
}
.modal-scroller {
  padding: 3rem;
  display: flex;
  justify-content: center;
}
.modal {
  width: 100%;
  max-width: 1200px;
  height: auto;
  min-height: calc(100vh - 6rem);
  padding: 4rem;
  background: var(--modal-background-color);
  position: relative;
  z-index: 102;
  border-radius: 8px;
}

/* Animations */

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.2s ease;
}
.slide-up-enter,
.slide-up-leave-active {
  opacity: 0;
  transform: translateY(30px);
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease;
}
</style>
