<template>
  <div class="sidebar" :class="{ open: sidebarOpen }">
    <div class="overlay" @click="closeSidebar()" />
    <div class="content">
      <div class="sticky-nav">
        <div class="nav-link">
          <router-link to="/">
            <IconMenuCalendar />
            <span class="label">Daily Notes</span>
          </router-link>
        </div>
        <div class="nav-link">
          <router-link to="/meetings">
            <IconMenuText />
            <span class="label">Meetings</span>
          </router-link>
        </div>
        <div class="nav-link">
          <router-link to="/tasks">
            <IconTask />
            <span class="badge">
              <TaskBadge :get-count-from-store="true" :hide-if-zero="true" />
            </span>
            <span class="label">Tasks</span>
          </router-link>
        </div>
        <div class="nav-link">
          <router-link to="/bookmarks">
            <IconBookmark />
            <span class="label">Bookmarks</span>
          </router-link>
        </div>
      </div>
      <div class="slider">
        <div class="close-btn" @click="closeSidebar()">
          <IconClose />
        </div>
        <div class="nav">
          <div class="nav-link">
            <router-link to="/">
              <span class="icon" />
              Daily Notes
            </router-link>
          </div>
          <div class="nav-link">
            <router-link to="/meetings">
              <span class="icon" />
              Meetings
            </router-link>
          </div>
          <div class="nav-link">
            <router-link to="/tasks">
              <span class="icon" />
              Tasks
            </router-link>
          </div>
          <div class="nav-link">
            <router-link to="/bookmarks">
              <span class="icon" />
              Bookmarks
            </router-link>
          </div>
        </div>
        <div class="tags">
          <Tags />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from '@/components/icons/Close';
import IconMenuCalendar from '@/components/icons/MenuCalendar';
import IconMenuText from '@/components/icons/MenuText';
import IconBookmark from '@/components/icons/Bookmark';
import IconTask from '@/components/icons/Task';
import Tags from '@/components/Tags';
import TaskBadge from '@/components/TaskBadge';

export default {
  name: 'Sidebar',
  components: {
    IconClose,
    IconMenuCalendar,
    IconMenuText,
    IconBookmark,
    IconTask,
    TaskBadge,
    Tags,
  },
  computed: {
    sidebarOpen() {
      return this.$store.getters.getConfig.sidebarOpen;
    },
  },
  methods: {
    closeSidebar() {
      this.$store.dispatch('updateConfig', { sidebarOpen: false });
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  width: 0px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: $zIndexSidebarMobile;

  &:after {
    display: none;
    content: '';
    position: fixed;
    left: 319px;
    top: 1.5rem;
    bottom: 1.5rem;
    width: 1px;
    background: var(--sidebar-border-color);
  }
}
@media (min-width: $tablet) {
  .sidebar {
    z-index: $zIndexSidebar;
  }
  // Show grey line
  .sidebar.open:after {
    display: block;
  }
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.07);
  display: none;
}

.content {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: $sidebar_width;
  transform: translateX(-320px);
  transition: transform 150ms ease-out;
}

.open {
  .content {
    overflow-y: auto;
    transform: translateX(0px);
    background-color: var(--sidebar-background-color);
  }
  .overlay {
    display: block;
  }
}

.slider {
  width: $sidebar_width;
  padding: 92px 1.5rem 2.5rem 1.5rem;
  transform: translateX(0px);
  transition: transform 150ms ease-out;
}

/* Tablet menu moves the inner content */
@media (min-width: $tablet) {
  .content {
    transform: translateX(0px);
  }
  .slider {
    transform: translateX(-320px);
  }
  .open {
    .slider {
      transform: translateX(0px);
    }
    .overlay {
      display: none;
    }
  }
}

.sticky-nav {
  position: absolute;
  top: 92px;
  left: 1.5rem;
  margin-top: 0;
  z-index: 3;
  font-size: 14px;
  font-weight: 500;

  a {
    position: relative;
  }

  .label {
    display: none;
    position: absolute;
    top: 5px;
    left: 36px;
    color: var(--sidebar-text-color);
    text-decoration: none;
    white-space: nowrap;
    font-weight: 500;
  }

  a:hover .label {
    display: block;
  }

  .badge {
    position: absolute;
    left: 1.5px;
    top: 1.5px;
  }
}

.nav {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.nav-link {
  height: 30px;
  margin-bottom: 6px;
  border-radius: 8px;

  &:hover a {
    color: var(--sidebar-text-active-color);

    .icon {
      background: var(--sidebar-menu-bg-color);
    }
  }

  a {
    color: var(--sidebar-text-color);
    text-decoration: none;
    display: flex;
    align-items: center;

    &.router-link-exact-active {
      color: var(--sidebar-text-active-color);

      .icon {
        background: var(--sidebar-menu-bg-color);
      }
    }
  }

  .icon {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 6px;
    display: grid;
    place-items: center;
    border-radius: 8px;

    /deep/ svg {
      width: 16px;
      height: 16px;
      fill: var(--sidebar-text-color);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.tags {
  margin: 0 0 2rem 0;
  font-weight: 500;
}

.mini-tasks {
  padding: 0 0.5rem;
}

// Hide labels if sidebar is open
.sidebar.open .sticky-nav .label {
  visibility: hidden;
}
// Hide tasks icon is sidebar is open
.sidebar.open .sticky-nav .nav-link-tasks {
  visibility: hidden;
  display: none;
}

// Close button
// only visible on mobile
.close-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: grid;
  place-items: center;

  .icon {
    width: 20px;
    height: 20px;
  }

  /deep/ svg {
    fill: var(--sidebar-text-active-color);
  }
}
@media (min-width: $tablet) {
  .close-btn {
    display: none;
  }
}
</style>
