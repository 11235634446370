<template>
  <transition name="fade-in">
    <div class="welcome">
      <div class="welcome-overlay" />
      <div v-scroll-lock="showContent" class="welcome-scroller">
        <transition name="slide-up" @after-leave="closeWelcome">
          <div v-if="showContent" class="welcome-dialog">
            <div class="welcome-pattern" />
            <div class="welcome-logo">
              <Logo />
            </div>
            <h1>
              Daily journal and<br />
              task tracker.
            </h1>
            <h2>
              We believe we’ve found the perfect way to capture notes &amp; thoughts throughout the day and
              <strong>stay on top of your priorities</strong> &amp; important tasks.
            </h2>
            <Button @onClick="clickTry">Try it out</Button>
            <small class="small-message"><i>Its free, no sign up or subscription required.</i></small>
            <div class="welcome-image">
              <img src="/screenshot_01.png" />
            </div>
            <div class="welcome-details">
              <h3>How it works</h3>
              <ul>
                <li>
                  No need to create pages or directories. Just press ‘<strong>Add a note</strong>’ and start writing!
                </li>
                <li>
                  Each new day starts with a blank slate for you to capture your notes, thoughts and tasks throughout
                  the day as they happen.
                </li>
                <li>Easily <strong>convert notes into Tasks</strong> to stay on top of your priorities.</li>
                <li>Organise your notes with #tags on the fly.</li>
                <li>Bookmark important or favourite notes so you can easily find them again later.</li>
                <li class="lighter">
                  <strong>COMING SOON</strong> Sync your notes to our secure cloud database to access them anywhere.
                </li>
              </ul>
            </div>
            <Button @onClick="clickTry">Get started now</Button>
            <div class="welcome-about">
              <h3>About Tueday</h3>
              <p>Here at Tueday we're trying to rethink the way that we capture notes everyday.</p>
              <p>
                We were sick of rushing to write down a note or task during a meeting on a scrap of paper or aimlessly
                trying to find an appropriate folder to start a new Google Doc that would ultimately end up getting
                disorganised and eventually lost anyway.
              </p>
              <p>
                After many years of trying different note taking practises, apps and techniques we decided to try solve
                our problem in a way that made sense to us! This is why we came up with
                <strong>Tueday - Note taking at the speed of thought</strong>.
                <router-link to="/about" @click.native="triggerClose">Read more</router-link>
              </p>
            </div>
            <div class="welcome-footer">
              <div>
                © 2021
                <router-link to="/about/privacy" @click.native="triggerClose">Privacy Policy</router-link>
                <router-link to="/about/terms" @click.native="triggerClose">Terms of use</router-link>
              </div>
              <div>
                <a href="mailto:hi@tueday.com">hi@tueday.com</a>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import dayjs from 'dayjs';
import Button from '@/components/Button';
import Logo from '@/components/Logo';

export default {
  name: 'Welcome',
  components: {
    Button,
    Logo,
  },
  data: () => ({
    showContent: false,
  }),
  computed: {
    currentYear() {
      return dayjs().format('YYYY');
    },
  },
  mounted() {
    this.showContent = true;
  },
  methods: {
    clickTry() {
      this.showContent = false;
      this.$gtag.event('Click:TryItOut', {
        event_category: 'Welcome',
      });
    },
    triggerClose() {
      this.showContent = false;
      this.$gtag.event('Click:OtherLink', {
        event_category: 'Welcome',
      });
    },
    closeWelcome() {
      this.$store.dispatch('updateConfig', { welcomeOpen: false });
      this.$store.dispatch('updateConfig', { acceptedWelcome: true });
    },
  },
};
</script>

<style scoped lang="scss">
.welcome {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 200;
}
.welcome-scroller {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.welcome-overlay {
  background: rgba(255, 255, 255, 0.98);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.welcome-dialog {
  position: relative;
  z-index: 201;
  background: $color_primary;
  max-width: 600px;
  width: 100%;
  height: auto;
  padding: 3rem;

  h1 {
    color: $color_black;
    font-size: 2.625rem;
    font-weight: 700;
    line-height: 1.15;
    letter-spacing: 0.06em;
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
  h2 {
    color: $color_black;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 2.5rem;

    strong {
      font-weight: 600;
    }
  }
  h3 {
    color: $color_black;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ul,
  p {
    font-size: 14px;
    color: $color_black;

    strong {
      font-weight: 600;
    }
  }

  ul {
    padding-left: 1rem;
  }

  .lighter {
    opacity: 0.6;
  }
}
.welcome-details {
  margin: 2rem 0;
}
.welcome-pattern {
  width: 230px;
  height: 640px;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: -1;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill-opacity="1" fill="%23f9df00"><rect width="10" height="10" /></svg>');
}
.welcome-logo {
  width: 118px;
  height: 36px;
  margin-top: 0px;
  margin-left: -7px;
  margin-bottom: 2rem;

  .logo {
    width: 100%;
    height: auto;
  }
}
.welcome-footer {
  color: $color_black;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
  font-weight: 600;
  margin-bottom: -0.5rem;

  a {
    color: inherit;
    margin-left: 1rem;
  }
}
.welcome-about {
  border-top: solid 1px $color_black;
  padding-top: 2.5rem;
  margin-top: 3rem;
}
.welcome-image {
  margin: 2.5rem -4px;

  img {
    width: 100%;
    height: auto;
  }
}
.small-message {
  color: $color_black;
  font-size: 14px;
  display: block;
  margin-top: 0.5rem;
}
/* Transitions */
.fade-in-enter,
.fade-in-leave-active {
  opacity: 0;
}
.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.2s ease;
}
.slide-up-enter,
.slide-up-leave-active {
  opacity: 0;
  transform: translateY(30px);
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease;
}
</style>
