<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7071 15.7071C21.0976 15.3166 21.0976 14.6834 20.7071 14.2929L15.7071 9.29289C15.3166 8.90237 14.6834 8.90237 14.2929 9.29289C13.9024 9.68342 13.9024 10.3166 14.2929 10.7071L17.5858 14L8 14C7.20435 14 6.44129 13.6839 5.87868 13.1213C5.31607 12.5587 5 11.7956 5 11L5 4C5 3.44771 4.55229 3 4 3C3.44772 3 3 3.44771 3 4L3 11C3 12.3261 3.52678 13.5979 4.46447 14.5355C5.40215 15.4732 6.67392 16 8 16L17.5858 16L14.2929 19.2929C13.9024 19.6834 13.9024 20.3166 14.2929 20.7071C14.6834 21.0976 15.3166 21.0976 15.7071 20.7071L20.7071 15.7071Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconArrowDownRight',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
