import { forEach } from 'lodash';
import Dexie from 'dexie';

import { DEFAULT_SETTINGS } from '@/common/constants';

const defaultSettingsState = {
  ...DEFAULT_SETTINGS,
};

// Database
const DB_NAME = 'tuedayIDB';
const DB_VERSION = 1;
let database;

// Queue
let timer;
const SAVE_TIMEOUT = 500; // How long the pause needs to be before saving changes
let noteQueue = {};

// Initliases the appropriate DB
const initDb = async () => {
  database = new Dexie(DB_NAME);

  database.version(DB_VERSION).stores({
    settings: '&key',
    tags: '&id',
    notes: '&id,type,listDate,parent',
  });

  // Populate settings
  database.on('populate', () => {
    forEach(defaultSettingsState, (value, key) => {
      database.settings.add({ key, value });
    });
  });

  database.open();
};

// Settings
// -------------------

// Getting settings
const getSettings = async () => {
  const array = await database.settings.orderBy('key').toArray();
  // converts array to { setting: value, ...}
  return array.reduce((obj, item) => ((obj[item.key] = item.value), obj), {});
};

// set a new setting or update an existing
const storeSetting = async (setting) => {
  forEach(setting, (value, key) => {
    database.settings.update(key, { key, value });
  });
};

// Tags
// -------------------
// Get all tags from DB
const getTags = async () => {
  return await database.tags.toArray();
};

// Get all tags from DB
const storeTag = async (tag) => {
  database.tags.put(tag, tag.id);
};

// Dlete tag from DB
const deleteTag = async (id) => {
  database.tags.delete(id);
};

// Notes
// -------------------

// Gets all notes
const getNotes = async () => {
  return await database.notes.toArray();
};

// Store note in DB
const storeNote = async (note) => {
  database.notes.put(note, note.id);
};

// Delete tag from DB
const deleteNote = async (id) => {
  database.notes.delete(id);
};

// Creates a write queue to stop race conditions.
// It adds each note edit as it comes in to queue
// If the note already exists, it replaces it and then runs the
// indexDB update once there is a pause in user actions
const queueUpdateNote = (event, id, data) => {
  noteQueue[id] = { event, data };
  clearTimeout(timer);
  // Runs the appropariate update when user pauses for 500ms
  timer = setTimeout(() => {
    Object.keys(noteQueue).forEach((key) => {
      const item = noteQueue[key];
      if (item.event === 'delete') {
        deleteNote(item.data);
      } else if (item.event === 'edit') {
        storeNote(item.data);
      }
    });
    // Clear queue
    clearNoteQueue();
  }, SAVE_TIMEOUT);
};

const clearNoteQueue = () => {
  noteQueue = {};
};

export {
  initDb,
  defaultSettingsState,
  getSettings,
  storeSetting,
  getTags,
  storeTag,
  deleteTag,
  storeNote,
  getNotes,
  deleteNote,
  queueUpdateNote,
};
