import { isEmpty } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';

import { DEFAULT_CONFIG, DEFAULT_SETTINGS } from '@/common/constants';
import { getSettings } from '@/db';
import notes from './modules/notes';
import noteTree from './modules/noteTree';
import tags from './modules/tags';
import plugins from './plugins';

const STORAGE_KEY_SETTINGS = process.env.VUE_APP_STORAGE_KEY_SETTINGS;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    caretPos: null,
    settings: {
      ...DEFAULT_SETTINGS,
    },
    config: {
      ...DEFAULT_CONFIG,
    },
  },
  getters: {
    getCaretPos: (state) => state.caretPos,
    getSettings: (state) => state.settings,
    getConfig: (state) => state.config,
  },
  mutations: {
    setCaretPos(state, pos) {
      state.caretPos = pos;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    updateSetting(state, newSetting) {
      state.settings = { ...state.settings, ...newSetting };
    },
    setConfig(state, config) {
      state.config = config;
    },
    updateConfig(state, newConfig) {
      state.config = { ...state.config, ...newConfig };
    },
  },
  actions: {
    async fetchSettings({ commit }) {
      // Get Config from localstorage
      // These settings are local to the device
      if (localStorage.getItem(STORAGE_KEY_SETTINGS)) {
        const config = JSON.parse(localStorage.getItem(STORAGE_KEY_SETTINGS));
        if (config) {
          commit('setConfig', config);
        }
      }
      // Get Settings from IndexDB
      // These settings persist with the user across devices
      let settings = await getSettings();
      if (!isEmpty(settings)) {
        commit('setSettings', settings);
      }
    },
    updateSetting({ commit }, newSetting) {
      commit('updateSetting', newSetting);
    },
    setCaretPos({ commit }, pos) {
      commit('setCaretPos', pos || null);
    },
    updateConfig({ commit }, newConfig) {
      commit('updateConfig', newConfig);
    },
  },
  modules: { notes, noteTree, tags },
  plugins,
});
