<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0166 3.21555C11.9096 2.78783 13.8902 2.98352 15.663 3.77342C16.1675 3.9982 16.7587 3.77146 16.9834 3.26699C17.2082 2.76252 16.9815 2.17134 16.477 1.94656C14.3103 0.981129 11.8896 0.74196 9.57581 1.26472C7.26206 1.78748 5.17929 3.04416 3.63811 4.84734C2.09693 6.65052 1.17992 8.90358 1.02384 11.2705C0.86777 13.6374 1.48099 15.9914 2.77206 17.9813C4.06312 19.9713 5.96285 21.4906 8.18792 22.3126C10.413 23.1346 12.8442 23.2154 15.1189 22.5428C17.3936 21.8703 19.39 20.4804 20.8103 18.5806C22.2306 16.6807 22.9986 14.3721 23 12V11.08C23 10.5277 22.5523 10.08 22 10.08C21.4477 10.08 21 10.5277 21 11.08V11.9994C20.9989 13.9402 20.3705 15.8286 19.2084 17.3831C18.0464 18.9375 16.413 20.0746 14.5518 20.6249C12.6907 21.1752 10.7015 21.1091 8.88102 20.4365C7.06051 19.764 5.50619 18.5209 4.44987 16.8928C3.39354 15.2646 2.89181 13.3387 3.01951 11.4021C3.14721 9.46552 3.89749 7.62211 5.15845 6.14678C6.41942 4.67145 8.12351 3.64326 10.0166 3.21555ZM22.7075 4.70675C23.0978 4.31603 23.0975 3.68287 22.7068 3.29254C22.316 2.90221 21.6829 2.90253 21.2925 3.29325L11.9996 12.5954L9.70711 10.3029C9.31658 9.91237 8.68342 9.91237 8.29289 10.3029C7.90237 10.6934 7.90237 11.3266 8.29289 11.7171L11.2929 14.7171C11.4805 14.9047 11.7349 15.0101 12.0002 15.01C12.2656 15.0099 12.52 14.9044 12.7075 14.7168L22.7075 4.70675Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconTask',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
