/* eslint-disable no-unused-vars */
import { debounce } from 'lodash';

import { storeSetting } from '@/db';

const STORAGE_KEY_SETTINGS = process.env.VUE_APP_STORAGE_KEY_SETTINGS;
const DEBUG = process.env.VUE_APP_DEBUG;

const TREE_CALC_TIMEOUT = 1500; // How long the pause needs to be before saving changes

const localStorePlugin = (store) => {
  store.subscribe((mutation, { settings, config }) => {
    // watch for SETTINGS update
    if (mutation.type.startsWith('updateSetting')) {
      if (DEBUG) console.log('IndexDB: saving setting...', settings);
      storeSetting(settings);
    }
    // watch for CONFIG update
    if (mutation.type.startsWith('updateConfig')) {
      if (DEBUG) console.log('LOCALSTORAGE: saving...');
      window.localStorage.setItem(STORAGE_KEY_SETTINGS, JSON.stringify(config));
    }
  });
};

// On note mutations in the fitire, relcaluate the noteTree
const onNoteStoreMutations = (store) => {
  const triggerStoreRebuilds = debounce(() => {
    store.dispatch('noteTree/recalculate');
    store.dispatch('tags/update');
  }, TREE_CALC_TIMEOUT);

  store.subscribe((mutation) => {
    if (mutation.type.startsWith('notes')) {
      triggerStoreRebuilds();
    }
  });
};

export default [localStorePlugin, onNoteStoreMutations];
