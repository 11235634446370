import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueTippy from 'vue-tippy';
import VScrollLock from 'v-scroll-lock';
import VueHotkey from 'v-hotkey';
import PortalVue from 'portal-vue';
import Notifications from 'vue-notification';

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import '@/styles/global.scss';

const GtagEnabled = process.env.NODE_ENV === 'production';

Vue.use(PortalVue);
Vue.use(VScrollLock);
Vue.use(VueHotkey);
Vue.use(Notifications);

Vue.use(VueTippy, {
  placement: 'left',
  size: 'small',
  delay: [500, 0],
  animation: 'shift-away',
});

// https://matteo-gabriele.gitbook.io/vue-gtag/methods/events
Vue.use(
  VueGtag,
  {
    config: { id: 'G-7RY4KKSPKH' },
    enabled: GtagEnabled,
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
