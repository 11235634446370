import { getNoteDomElement } from '@/utils/dom';

// const LINE_HEIGHT = 24;
const LINE_HEIGHT_LARGE = 30;
const PIXEL_OFFSET = 3; // mapped to padding on content-editable

const focusNode = (nodeId) => {
  const element = getNoteDomElement(nodeId);
  if (element) {
    element.focus();
  }
};

const moveCursorToStart = (nodeId) => {
  setCaretPosition(nodeId, 0);
};

const moveCursorToEnd = (nodeId) => {
  const element = getNoteDomElement(nodeId);
  if (element) {
    element.focus();
    // select all the content in the element
    document.execCommand('selectAll', false, null);
    // collapse selection to the end
    document.getSelection().collapseToEnd();
  }
};

// removes any text selection
const clearSelection = (selection) => {
  const sel = selection || window.getSelection();
  if (sel) {
    sel.removeAllRanges();
  }
  if (document.activeElement) {
    document.activeElement.blur();
  }
};

// based on: https://medium.com/javascript-in-plain-english/how-to-find-the-caret-inside-a-contenteditable-element-955a5ad9bf81
const getCaretPosition = (noteId) => {
  let caretPos = 0;
  const element = getNoteDomElement(noteId);
  if (element) {
    const selection = window.getSelection();
    // Check if there is a selection (i.e. cursor in place)
    if (selection.rangeCount !== 0) {
      // Store the original range
      const range = window.getSelection().getRangeAt(0);
      // Clone the range
      const preCaretRange = range.cloneRange();
      // Select all textual contents from the contenteditable element
      preCaretRange.selectNodeContents(element);
      // And set the range end to the original clicked position
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      // Return the text length from contenteditable start to the range end
      caretPos = preCaretRange.toString().length;
    }
  }
  return caretPos;
};

// based on: https://stackoverflow.com/questions/6249095/how-to-set-caretcursor-position-in-contenteditable-element-div
const setCaretPosition = (nodeId, position) => {
  const element = getNoteDomElement(nodeId);
  if (element && element.innerText) {
    const targetText = element.innerText;
    // handle length being shorter than new position
    const newPosition = targetText.length < position ? targetText.length : position;

    element.focus();

    if (newPosition >= 0) {
      const selection = window.getSelection();
      let range = createRange(element, { count: newPosition });
      if (range) {
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  } else {
    element.focus();
  }
};

const createRange = (node, chars, range) => {
  if (!range) {
    range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);
  }
  if (chars.count === 0) {
    range.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        range.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);

        if (chars.count === 0) {
          break;
        }
      }
    }
  }

  return range;
};

// Determines if Caret is at the Top or Bottom of its container and therefore about to 'exit' it
const getCaretInfo = (nodeId) => {
  let caretOffsetTop = 0;

  const caret = {
    top: false,
    bottom: false,
    line: 0,
    x: 0,
    y: 0,
  };

  const element = getNoteDomElement(nodeId);
  const lineHeight = LINE_HEIGHT_LARGE; //TODO - why does this work? - should be 24

  if (element) {
    // Get the dimensions of the editor
    const elemTop = element.getBoundingClientRect().y;
    const elemHeight = element.getBoundingClientRect().height;
    // Calc the Line count based on editor height / line-height
    const elemLineCount = elemHeight / lineHeight;

    const selection = window.getSelection();

    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      // Retrieve the current line
      const rects = range.getClientRects()[0];
      // If the rect object is returned
      if (rects) {
        caretOffsetTop = rects.top;
        caret.y = rects.top;
        caret.x = rects.left;
      } else {
        // Create dummy element to get y position of the caret as we couldn't use the rect
        var dummy = document.createElement('span');
        dummy.id = 'findCaretHelper';
        range.insertNode(dummy);
        caretOffsetTop = dummy.offsetTop;
        element.removeChild(dummy);
      }
    }
    const line = Math.ceil((caretOffsetTop - PIXEL_OFFSET - elemTop) / lineHeight) + 1;
    caret.line = line > 0 ? line : 1; // handle negative line numbers (can happen when line is empty)
    caret.bottom = caret.line >= elemLineCount;
    caret.top = caret.line <= 1;
  }
  return caret;
};

const moveCursorFromTo = (fromId, toId) => {
  const caretPos = getCaretPosition(fromId);
  setCaretPosition(toId, caretPos);
};

const shiftCaretTo = (nodeId, pos, location = 'start', el = null) => {
  const element = el || getNoteDomElement(nodeId);
  // console.log(element)
  const marginOffset = PIXEL_OFFSET + 1;

  element.focus();

  if (element) {
    const elemRect = element.getBoundingClientRect();
    const elemX = elemRect.x;
    const elemTop = elemRect.y;
    const elemBottom = elemRect.bottom;
    const newTop = elemTop + marginOffset;
    const newBottom = elemBottom - marginOffset;

    // if we're arriving from the top, add 1px margin offset
    const newY = location === 'start' ? newTop : newBottom;
    const newX = pos.x < elemX ? elemX : pos.x;

    // Move selection to the prescribed XY coords
    createSelectionFromPoint(newX, newY, newX, newY);
  }
};

const createSelectionFromPoint = (startX, startY, endX, endY) => {
  const doc = document;
  let start;
  let end;
  let range = null;

  // if caretPositionFromPoint is supported
  if (typeof doc.caretPositionFromPoint != 'undefined') {
    start = doc.caretPositionFromPoint(startX, startY);
    end = doc.caretPositionFromPoint(endX, endY);
    range = doc.createRange();
    range.setStart(start.offsetNode, start.offset);
    range.setEnd(end.offsetNode, end.offset);
  } else if (typeof doc.caretRangeFromPoint != 'undefined') {
    // ielse use caretRangeFromPoint
    start = doc.caretRangeFromPoint(startX, startY);
    end = doc.caretRangeFromPoint(endX, endY);
    range = doc.createRange();
    range.setStart(start.startContainer, start.startOffset);
    range.setEnd(end.startContainer, end.startOffset);
  }

  if (range !== null && typeof window.getSelection != 'undefined') {
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof doc.body.createTextRange != 'undefined') {
    range = doc.body.createTextRange();
    range.moveToPoint(startX, startY);
    const endRange = range.duplicate();
    endRange.moveToPoint(endX, endY);
    range.setEndPoint('EndToEnd', endRange);
    range.select();
  }
};

export {
  clearSelection,
  createSelectionFromPoint,
  focusNode,
  getCaretInfo,
  getCaretPosition,
  moveCursorToStart,
  moveCursorToEnd,
  moveCursorFromTo,
  setCaretPosition,
  shiftCaretTo,
};
