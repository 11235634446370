<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1104 2.00611C10.6593 2.0671 11.0548 2.56152 10.9938 3.11043L10.4506 8H14.4383L15.0061 2.88957C15.0671 2.34066 15.5615 1.94512 16.1104 2.00611C16.6593 2.0671 17.0548 2.56152 16.9938 3.11043L16.4506 8H20C20.5523 8 21 8.44772 21 9C21 9.55228 20.5523 10 20 10H16.2283L15.7839 14H20C20.5523 14 21 14.4477 21 15C21 15.5523 20.5523 16 20 16H15.5617L14.9938 21.1104C14.9329 21.6593 14.4384 22.0549 13.8895 21.9939C13.3406 21.9329 12.9451 21.4385 13.0061 20.8896L13.5494 16H9.56168L8.99385 21.1104C8.93286 21.6593 8.43844 22.0549 7.88953 21.9939C7.34063 21.9329 6.94509 21.4385 7.00608 20.8896L7.54937 16H4C3.44772 16 3 15.5523 3 15C3 14.4477 3.44772 14 4 14H7.77159L8.21603 10H4C3.44772 10 3 9.55228 3 9C3 8.44772 3.44772 8 4 8H8.43826L9.00608 2.88957C9.06707 2.34066 9.56149 1.94512 10.1104 2.00611ZM13.7716 14L14.216 10H10.2283L9.7839 14H13.7716Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconHash',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
