<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.68774 12.7834C3.68774 7.81288 7.71718 3.78345 12.6877 3.78345C17.6583 3.78345 21.6877 7.81288 21.6877 12.7834C21.6877 17.754 17.6583 21.7834 12.6877 21.7834C7.71718 21.7834 3.68774 17.754 3.68774 12.7834ZM12.6877 1.78345C6.61261 1.78345 1.68774 6.70831 1.68774 12.7834C1.68774 18.8586 6.61261 23.7834 12.6877 23.7834C18.7629 23.7834 23.6877 18.8586 23.6877 12.7834C23.6877 6.70831 18.7629 1.78345 12.6877 1.78345ZM13.6877 6.78345C13.6877 6.23116 13.24 5.78345 12.6877 5.78345C12.1355 5.78345 11.6877 6.23116 11.6877 6.78345V12.7834C11.6877 13.1622 11.9017 13.5085 12.2405 13.6779L16.2405 15.6779C16.7345 15.9249 17.3352 15.7246 17.5822 15.2307C17.8292 14.7367 17.6289 14.136 17.135 13.889L13.6877 12.1654V6.78345Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconClock',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
