import dayjs from 'dayjs';
import store from '@/store';
import { EventBus, SELECT_NOTES } from '@/event-bus';
import { POSITION_INCREMENT, TASK_OBJ } from '@/common/constants';
import { findAllChildrenNotes, getNoteDomElement } from '@/utils/dom';
import { focusNode, moveCursorToEnd } from '@/utils/caret';
import { parseTagsFromString } from '@/utils/text';

const SELECTED_NOTE_CLASS = 'xSelected';

const generateNoteId = (id, zone = 'note') => {
  return `${zone}-${id}`;
};

// Add New Note to List
/**
 * Type: note|task|event
 * Parent: null|parentID
 * ListDate: null|ListDate
 * Content: string
 * */
const addNoteTolist = async (
  { type = 'note', parent = null, listDate = null, content = '', taskGroup = '' },
  list = [],
  insertAt = 'start'
) => {
  const now = dayjs();
  // Create Default Note Object
  const noteObj = {
    content,
    parent,
    type,
    listDate: listDate || now.format('YYYY-MM-DD'),
    position: getInsertPosition(insertAt, list),
  };
  // Extend with Task Object if is Type:Task
  if (type === 'task') {
    noteObj.task = newTaskObj();
    // Set task group (todo, later etc)
    noteObj.task.group = taskGroup;
  }
  // Dispatch to add Note
  const newNoteId = await store.dispatch('notes/add', noteObj);
  // move cursor to new note
  focusNode(newNoteId);
};

// Changes the note Type
// If the note is a task, it adds the required Task Obj properties
const changeNoteType = (note, type) => {
  if (type && type !== note.type) {
    const updatedNote = {
      ...note,
      type,
      ...(type === 'task' && { task: newTaskObj() }),
    };
    updateExistingNote(updatedNote);
  }
};

// Mark Task as complete
// - Adds a completed Date
// - Hides the children
const markTaskAsComplete = (note) => {
  const updatedNote = {
    ...note,
    hideChildren: true,
    task: {
      ...note.task,
      completed: !note.task.completed,
      completedDate: dayjs().format(),
    },
  };
  updateExistingNote(updatedNote);
};

// Mark Task as todo later
const setTaskGroup = (note, group) => {
  if (group) {
    const updatedNote = {
      ...note,
      task: {
        ...note.task,
        group: group,
      },
    };
    updateExistingNote(updatedNote);
  }
};

// Saves an updated Note object back to the store
// This is debounced to stop it overwhelming the remote DB
const updateExistingNote = (note) => {
  const tags = parseTagsFromString(note.content);
  if (note && note.id) {
    store.dispatch('notes/edit', { ...note, tags });
  }
};

// Delete a note
const deleteNote = (noteDomID, noteId, hasChildren = false, force = false) => {
  // Don't delete if the note has children
  // unless FORCED
  if (!hasChildren || force) {
    // If Forcing delete and there are children, batch thoses deletes
    if (hasChildren) {
      // Delete the note and all its children
      deleteNoteAndAllChildren(noteId);
    } else {
      // Just delete the Note
      store.dispatch('notes/batchRemove', [noteId]);
    }
    // Move cursor to previous Node
    const { previousDomId } = getPreviousDomNote(noteDomID);
    if (previousDomId) {
      // move cursor to previous note
      moveCursorToEnd(previousDomId);
    }
  } else {
    console.log('Delete Failed! There are children nodes that depend on this note');
  }
};

// Finds and deletes all the Children of a note
const deleteNoteAndAllChildren = (noteId) => {
  const childrenNotes = findAllChildrenNotes(noteId);
  const noteAndChildren = [noteId, ...childrenNotes];
  store.dispatch('notes/batchRemove', noteAndChildren);
};

const newTaskObj = () => {
  return { ...TASK_OBJ, position: createDatePosition() };
};

const createDatePosition = () => {
  return Math.floor(Date.now() / 1000);
};

// Determines the appropriate insert position on the list for the new note
// insertPosition = noteId, or 'start' or 'end'
const getInsertPosition = (insertPosition = 'start', notes) => {
  let newPosition = POSITION_INCREMENT;

  // if there are no notes in the array, insert at the default position
  if (!notes || !notes.length) {
    return newPosition;
  }

  // if we adding to the end of the list, get the last item and increment
  if (insertPosition === 'end') {
    const lastNote = getLastNote(notes);
    return lastNote.position + POSITION_INCREMENT;
  }

  // if we adding to the start of the list, get the first item and divide to insert
  if (insertPosition === 'start') {
    const firstNote = notes[0];
    return firstNote.position / 2;
  }

  // else assume 'insertPosition' is a Note ID and attempt to figure out where we can insert it
  const index = getNoteIndex(insertPosition, notes);
  const nextNote = getNextNote(insertPosition, notes);
  const currNote = notes[index];

  if (currNote && nextNote) {
    // inserting inbetween
    newPosition = (nextNote.position - currNote.position) / 2 + currNote.position;
  } else if (!currNote && nextNote) {
    // inserting at start
    newPosition = nextNote.position / 2;
  } else if (currNote && !nextNote) {
    // inserting at end
    newPosition = currNote.position + POSITION_INCREMENT;
  }

  return newPosition;
};

// Calculates the Notes Position # on DragnDrop
const getDropPosition = (notes, index) => {
  let newPosition = POSITION_INCREMENT; // used if list is empty
  const prev = notes[index == 0 ? false : index - 1];
  const next = notes[index == notes.length - 1 ? false : index + 1];

  if (prev && next) {
    // inbetween two items
    newPosition = (next.position - prev.position) / 2 + prev.position;
  } else if (!prev && next) {
    // start of list
    newPosition = next.position / 2;
  } else if (prev && !next) {
    // end of list
    newPosition = prev.position + POSITION_INCREMENT;
  }
  return newPosition;
};

// calculates the Notes TASK postition on DragnDrop
const getTaskDropPosition = (notes, index) => {
  let newPosition = POSITION_INCREMENT;
  const prev = notes[index == 0 ? false : index - 1];
  const next = notes[index == notes.length - 1 ? false : index + 1];

  if (prev && next) {
    // inbetween two items
    newPosition = (next.task.position - prev.task.position) / 2 + prev.task.position;
  } else if (!prev && next) {
    // start of list
    newPosition = next.task.position / 2;
  } else if (prev && !next) {
    // end of list
    newPosition = prev.task.position + POSITION_INCREMENT;
  }
  return newPosition;
};

const getNoteIndex = (noteId, notes) => {
  return notes.findIndex((note) => note.id === noteId);
};

const getPreviousNote = (noteId, notes) => {
  const noteIndex = notes.findIndex((n) => n.id === noteId);
  return noteIndex ? notes[noteIndex - 1] : false;
};

const getNextNote = (noteId, notes) => {
  const noteIndex = getNoteIndex(noteId, notes);
  return notes[noteIndex == notes.length - 1 ? false : noteIndex + 1];
};

const getLastNote = (notes) => notes[notes.length - 1];

const getPreviousDomNote = (currentNoteId) => {
  console.log(currentNoteId);
  const targetElement = getNoteDomElement(currentNoteId);
  const nodeList = targetElement.closest('.nl');
  // find previous note in DOM array
  const contentBlocks = nodeList.getElementsByClassName('ced');
  for (var i = 0; i < contentBlocks.length; i++) {
    if (contentBlocks[i].id == currentNoteId) {
      const previousDomNode = i > 0 ? contentBlocks[i - 1] : false;
      if (previousDomNode) {
        const previousDomId = previousDomNode.id;
        const previousNoteId = previousDomNode.dataset.id;
        return {
          previousDomId,
          previousNoteId,
          element: previousDomNode,
        };
      }
    }
  }
  return false;
};

const getNextDomNote = (currentNoteId) => {
  const targetElement = getNoteDomElement(currentNoteId);
  const nodeList = targetElement.closest('.nl');
  // find next note in DOM array
  const contentBlocks = nodeList.getElementsByClassName('ced');
  for (var i = 0; i < contentBlocks.length; i++) {
    if (contentBlocks[i].id === currentNoteId) {
      const nextDomNode = i < contentBlocks.length ? contentBlocks[i + 1] : false;
      if (nextDomNode) {
        const nextDomId = nextDomNode.id;
        const nextNoteId = nextDomNode.dataset.id;
        return {
          nextDomId,
          nextNoteId,
          element: nextDomNode,
        };
      }
    }
  }
  return false;
};

const getAllNotes = () => {
  return document.getElementsByClassName('note-content');
};

// Finds all the notes between two classes
// !! Deprecated
const getAllNotesBetween = () => {
  let firstIndex = null;
  let lastIndex = null;
  // all notes
  const allNotes = getAllNotes();
  // loop to find notes
  for (var i = 0; i < allNotes.length; i++) {
    const note = allNotes[i];
    if (note.classList.contains(SELECTED_NOTE_CLASS)) {
      if (!firstIndex) firstIndex = i + 1; // + 1 to get next note
      lastIndex = i;
    }
  }
  // Return the notes between the indexes (if both are set)
  if (firstIndex && lastIndex) {
    const notesArr = [...allNotes];
    const foundNotes = notesArr.slice(firstIndex, lastIndex);
    return foundNotes.map((note) => {
      return note.getAttribute('data-id');
    });
  }
  // else return nothing
  return [];
};

// selects notes between two
// TODO needs consistent use in regards to adding/removing classes to work
const selectNotesBetween = () => {
  let firstIndex = null;
  let lastIndex = null;
  // all notes
  const allNotes = getAllNotes();
  const notesArr = [...allNotes];

  // loop to find start and end of note selection
  notesArr.forEach((element, index) => {
    if (element.classList.contains(SELECTED_NOTE_CLASS)) {
      if (!firstIndex) firstIndex = index + 1; // + 1 to get next note
      lastIndex = index; // set last index until the last is found
    }
  });

  // Return the notes between the indexes (if both are set)
  if (firstIndex && lastIndex) {
    const foundNotes = notesArr.slice(firstIndex, lastIndex);
    publishNoteSelection(foundNotes);
  }
};

// select all notes on page
const selectAllNotes = () => {
  const allNotes = getAllNotes();
  publishNoteSelection(allNotes);
};

// select notes that are passed in
const publishNoteSelection = (notes) => {
  const noteIds = [...notes].map((note) => note.getAttribute('data-id'));
  if (noteIds && noteIds.length) {
    // publish array of notes selected
    EventBus.$emit(SELECT_NOTES, noteIds);
  }
};

// Gets an array of notes and removes duplicates in the tree
const removeDuplicateNotes = (notes) => {
  // Get the Ancestor Tree
  const tree = store.getters['noteTree/tree'];
  // reduce the found IDS to an array for easy comparison
  const taskIds = notes.map((o) => o.id);
  // Filter out any notes that have common ancestors
  return notes.filter((item) => {
    const ancestors = tree[item.id];
    if (!ancestors || !ancestors.some((r) => taskIds.includes(r))) return item;
  });
};

const groupNotesBy = (items, key) => {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );
};

export {
  SELECTED_NOTE_CLASS,
  generateNoteId,
  addNoteTolist,
  deleteNote,
  changeNoteType,
  newTaskObj,
  updateExistingNote,
  markTaskAsComplete,
  setTaskGroup,
  getInsertPosition,
  getDropPosition,
  getTaskDropPosition,
  getNoteIndex,
  getPreviousNote,
  getNextNote,
  getLastNote,
  getPreviousDomNote,
  getNextDomNote,
  getAllNotesBetween,
  selectNotesBetween,
  removeDuplicateNotes,
  groupNotesBy,
  selectAllNotes,
  publishNoteSelection,
};
