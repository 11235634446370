<template>
  <div
    :id="`${zone}-${id}`"
    :data-id="id"
    :class="{ completed: isCompletedTask, meeting: type === 'event' }"
    class="content-editable ced"
    contenteditable="true"
    spellcheck="true"
    data-ph="Enter a note"
    @input="onInput"
    @click="onClick($event)"
    @keydown="onKeyDown($event)"
    @keydown.enter.prevent
    @keyup.enter="onEnter($event)"
    @keyup.esc="onEsc()"
    @keydown.delete="onBackspace($event)"
    @keydown.tab="onTab($event)"
    @keydown.down="onArrowDown($event)"
    @keydown.up="onArrowUp($event)"
    @keydown.right="onArrowRight($event)"
    @keydown.left="onArrowLeft($event)"
    @mouseenter="onMouseEnter($event)"
    @mouseleave="onMouseLeave($event)"
    @focus="onFocus($event)"
    @blur="onBlur($event)"
    @paste="onPaste"
    ref="input"
  />
</template>

<script>
import { handleInternalAnchorClick } from '@/utils';
import { getCaretPosition, getCaretInfo } from '@/utils/caret';
import {
  splitStringOnCursor,
  highlightSubStringInString,
  linkifyString,
  linkifyHashTag,
  linkifyMentions,
  isSlashCommand,
} from '@/utils/text';
import { pasteTextIntoNote } from '@/utils/copyPaste';

let onInputTimeout = 0;

export default {
  name: 'ContentEditor',
  props: {
    id: {
      type: String,
      require: true,
      default: '',
    },
    isCompletedTask: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    zone: {
      type: String,
      require: true,
      default: '',
    },
  },
  data: () => ({
    highlightString: '',
    active: false,
    value: '',
  }),
  watch: {
    '$route.params.query'(query) {
      this.highlightString = query || '';
      // this.updateInnerHtml(this.value);
    },
    content(newString, oldString) {
      // If the value changes and the input isn't active, update it
      if (newString !== oldString && !this.active) {
        this.setValue(newString);
      }
    },
  },
  mounted() {
    this.highlightString = this.$route.params.query;
    this.setValue(this.content);
  },
  methods: {
    setValue(string) {
      this.value = string;
      this.updateInnerHtml(string);
    },
    updateValue(string) {
      this.updateInnerHtml(string);
      this.$emit('valueUpdated', string);
    },
    updateInnerHtml(string) {
      this.$refs.input.innerHTML = this.formatText(string);
    },
    onClick(e) {
      this.resetCaretCoords();
      handleInternalAnchorClick(e);
    },
    onFocus() {
      this.active = true;
    },
    onBlur(e) {
      this.active = false;
      this.setValue(e.target.innerText);
    },
    onKeyDown(e) {
      if (e.keyCode !== 38 && e.keyCode !== 40) {
        // reset cursorPos state if not up or down arrows
        this.resetCaretCoords();
      }
    },
    onInput(e) {
      clearTimeout(onInputTimeout);
      // Ignore keypresses from special keys (arrows, tabs, enter etc)
      if (![9, 13, 16, 17, 18, 20, 27, 37, 38, 39, 40].includes(e.keyCode)) {
        // Consider checking if value has changed as well to reduce writes
        // Set timeout to improve performance.
        // TODO this needs to be further explored to reduce reliance on all the deboucing everywhere
        // This also introduces race condition bugs
        // This component should probably maintain its own state and only occasionaly send out updates
        // It's a bit cyclical at the moment
        this.value = e.target.innerText;
        // Debounce and publish update
        onInputTimeout = setTimeout(() => {
          this.$emit('valueUpdated', this.value);
        }, 500);
      }
    },
    onEnter(e) {
      if (this.value) {
        // check if the value is a slash command or contains one
        const slashCommand = isSlashCommand(this.value);
        if (slashCommand) {
          this.execSlashCommand(slashCommand);
        } else {
          // if SHIFT ENTER
          const shiftKey = !!e.shiftKey;
          // Else create new note
          const currentNoteId = this.$el.id;
          // Check if the cursor splits the text in two
          const [before, after] = this.determineLineBreak(this.value, currentNoteId);
          // If the line has been split, update the original note text
          if (before !== this.value) {
            this.updateValue(before);
          }
          // Push after into the addNote method
          this.$emit('addNote', { value: after, enterChild: shiftKey });
        }
      }
    },
    onEsc() {
      if (!this.value.length) {
        clearTimeout(onInputTimeout);
        this.$emit('deleteNote');
      } else {
        this.$refs.input.blur();
      }
    },
    onBackspace(e) {
      // If there is no value in the input, request a delete
      if (!this.value.length) {
        clearTimeout(onInputTimeout);
        this.$emit('deleteNote');
        return e.preventDefault();
      } else {
        // There is an existing value
        const currentNoteId = this.$el.id;
        const [before, after] = this.determineLineBreak(this.value, currentNoteId);
        // if the cursor is at the start of the input, then we're gonna attempt to merge
        // with the above note
        if (!before && after) {
          this.$emit('mergeNoteUp', { value: after });
          return e.preventDefault();
        }
      }
    },
    onTab(e) {
      e.preventDefault();
      // clear timeout and force value update
      clearTimeout(onInputTimeout);
      // This forces a save if TAB is pressed too quickly after typing
      this.$emit('valueUpdated', this.value);
      // if SHIFT + TAB
      if (e.shiftKey) {
        // Move the Node Out <-
        this.$emit('stepOut');
        return;
      }
      // else TAB
      // Move the Node In ->
      this.$emit('stepIn');
    },
    onArrowDown(e) {
      // IF CTRL or CMD deteched
      if (e.ctrlKey || e.metaKey) {
        // TODO: only toggle open
        this.$emit('toggleChildren');
        return e.preventDefault();
      }
      // No other keys
      const currentNoteId = this.$el.id;
      const caret = getCaretInfo(currentNoteId);
      if (caret.bottom) {
        const pos = this.getCaretCoords(caret);
        this.$emit('moveToNextNote', { currentNoteId, pos });
        return e.preventDefault();
      }
    },
    onArrowUp(e) {
      // IF CTRL or CMD deteched
      if (e.ctrlKey || e.metaKey) {
        // TODO: only toggle close
        this.$emit('toggleChildren');
        return e.preventDefault();
      }
      // No other keys
      const currentNoteId = this.$el.id;
      const caret = getCaretInfo(currentNoteId);
      if (caret.top) {
        const pos = this.getCaretCoords(caret);
        this.$emit('moveToPreviousNote', { currentNoteId, pos });
        return e.preventDefault();
      }
    },
    onArrowRight(e) {
      // if SHIFT + Right Arrow
      if (e.shiftKey) {
        this.$emit('navigateToNote');
        return e.preventDefault();
      }
    },
    onArrowLeft(e) {
      // if SHIFT + Left Arrow
      if (e.shiftKey) {
        this.$emit('navigateToRootParent');
        return e.preventDefault();
      }
    },
    onPaste(e) {
      pasteTextIntoNote(e);
    },
    onMouseEnter(e) {
      this.toggleLinkClickable(e.target, 'false');
    },
    onMouseLeave(e) {
      this.toggleLinkClickable(e.target, 'true');
    },
    resetCaretCoords() {
      this.$store.dispatch('setCaretPos', null);
    },
    setCaretCoords(coords) {
      this.$store.dispatch('setCaretPos', coords);
    },
    getCaretCoords(existingCaret) {
      let caretPos = this.$store.getters['getCaretPos'];
      // check if caretPos is set, if not, set it
      if (!caretPos) {
        const { x, y } = existingCaret;
        caretPos = { x, y };
        this.setCaretCoords(caretPos);
      }
      return caretPos;
    },
    determineLineBreak(string, noteId) {
      const caretPos = getCaretPosition(noteId);
      return splitStringOnCursor(string, caretPos);
    },
    formatText(text) {
      // Formats incoming string
      let content = highlightSubStringInString(text, this.highlightString);
      content = linkifyString(content);
      content = linkifyHashTag(content);
      content = linkifyMentions(content);
      return content;
    },
    toggleLinkClickable(el, bool) {
      const links = el.querySelectorAll('a');
      links.forEach((element) => {
        element.setAttribute('contenteditable', bool);
      });
    },
    execSlashCommand(slashCommand) {
      if (slashCommand.command === 'changeType') {
        // TODO this only works if command is the only thing in the input
        // consider if the command is anywhere in the string
        this.$emit('changeType', slashCommand.type);
        // Clear input of command
        this.$nextTick(() => {
          this.updateValue('');
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-editable {
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: left;

  &.completed {
    text-decoration: line-through;
    text-decoration-color: rgba(0, 0, 0, 0.4);
  }

  &.meeting {
    font-weight: 600;
  }

  /deep/ .highlight {
    background: var(--search-highlight-background);
    color: var(--search-highlight-text-color);
  }

  /deep/ .link {
    cursor: pointer;
    color: var(--text-link-color);

    &:hover {
      color: var(--text-color);
    }
  }

  /deep/ .hashtag {
    color: var(--text-color);
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
