<template>
  <div id="container" ref="container">
    <slot />
  </div>
</template>

<script>
import { SELECTED_NOTE_CLASS } from '@/utils/notes';
import { clearSelection } from '@/utils/caret';
// https://github.com/andi23rosca/drag-select-vue/blob/master/src/DragSelect.vue

const getDimensions = (p1, p2) => ({
  width: Math.abs(p1.x - p2.x),
  height: Math.abs(p1.y - p2.y),
});

const collisionCheck = (node1, node2) =>
  node1.left < node2.left + node2.width &&
  node1.left + node1.width > node2.left &&
  node1.top < node2.top + node2.height &&
  node1.top + node1.height > node2.top;

const addSelectedClass = (notes) => {
  clearSelection();
  if (notes) {
    // remove existing class first
    [...document.querySelectorAll(`.${SELECTED_NOTE_CLASS}`)].forEach((el) => el.classList.remove(SELECTED_NOTE_CLASS));
    // then add it back
    [...notes].forEach((element) => {
      element.classList.add(SELECTED_NOTE_CLASS);
    });
  }
};

const isNotBlockedElement = (event) => {
  const isDragHandle = event.target.closest('.handle');
  // TODO add more things that don't allow drag selection
  if (isDragHandle) return false;
  // else
  return true;
};

const isLeftClick = (event) => {
  return event.button === 0;
};

export default {
  props: {
    borderColor: {
      type: String,
      default: '#B3E2F6',
    },
    backgroundColor: {
      type: String,
      default: 'rgba(179,226,246,0.15)',
    },
  },
  data() {
    return {
      intersected: [],
    };
  },
  watch: {
    intersected(curr) {
      // adds a 'selection' class
      addSelectedClass(curr);
    },
  },
  mounted() {
    const { container } = this.$refs;
    const self = this;

    let containerRect = container.getBoundingClientRect();

    const getCoords = (e) => ({
      x: e.clientX - containerRect.left,
      y: e.clientY - containerRect.top,
    });

    let notes = [];

    let box = document.createElement('div');
    box.setAttribute('data-drag-box-component', '');
    box.style.position = 'absolute';
    box.style.backgroundColor = this.backgroundColor;
    box.style.border = `dashed 1px ${this.borderColor}`;
    box.style.zIndex = `20`;
    box.style.display = 'none';

    const delta = 6;
    let start = { x: 0, y: 0 };
    let end = { x: 0, y: 0 };

    function intersection() {
      const rect = box.getBoundingClientRect();
      const intersected = [];
      for (let i = 0; i < notes.length; i++) {
        if (collisionCheck(rect, notes[i].getBoundingClientRect())) {
          intersected.push(notes[i]);
        }
      }
      if (JSON.stringify([...intersected]) !== JSON.stringify([...self.intersected])) self.intersected = intersected;
    }

    // Touch events disabled until they can be debugged
    // They were breaking all mobile interaction
    function touchStart(e) {
      //e.preventDefault();
      // startDrag(e.touches[0]);
    }
    function touchMove(e) {
      // e.preventDefault();
      // drag(e.touches[0]);
    }

    function startDrag(e) {
      if (isNotBlockedElement(e) && isLeftClick(e)) {
        containerRect = container.getBoundingClientRect();
        start = getCoords(e);
        end = start;
        // set event listeners
        document.addEventListener('mousemove', drag);
        document.addEventListener('touchmove', touchMove);
        // box styles and add to page
        box.style.top = start.y + 'px';
        box.style.left = start.x + 'px';
        container.prepend(box);

        // find notes that intersect box
        intersection();
      }
    }

    function drag(e) {
      if (isNotBlockedElement(e)) {
        end = getCoords(e);
        const dimensions = getDimensions(start, end);

        // dont draw rect if havn't moved enough
        if (dimensions.width > delta && dimensions.height > delta) {
          // get notes
          notes = notes.length ? notes : document.getElementsByClassName('note-content');

          box.style.display = 'block';

          if (end.x < start.x) {
            box.style.left = end.x + 'px';
          }
          if (end.y < start.y) {
            box.style.top = end.y + 'px';
          }
          box.style.width = dimensions.width + 'px';
          box.style.height = dimensions.height + 'px';

          // find notes that intersect box
          intersection();
        }
      }
    }

    function endDrag() {
      start = { x: 0, y: 0 };
      end = { x: 0, y: 0 };
      box.style.width = 0;
      box.style.height = 0;
      box.style.display = 'none';

      document.removeEventListener('mousemove', drag);
      document.removeEventListener('touchmove', touchMove);
      box.remove();

      notes = []; // reset notes
    }

    container.addEventListener('mousedown', startDrag);
    container.addEventListener('touchstart', touchStart);
    document.addEventListener('mouseup', endDrag);
    document.addEventListener('touchend', endDrag);

    this.$once('on:destroy', () => {
      container.removeEventListener('mousedown', startDrag);
      container.removeEventListener('touchstart', touchStart);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('touchend', endDrag);
    });
  },
};
</script>

<style scoped lang="scss">
#container {
  position: relative;
  overflow: hidden;
}
</style>
