<template>
  <div class="tag-list">
    <draggable :list="tags" group="tags" ghost-class="ghost" @change="onDragChange" class="dragArea">
      <TagItem v-for="tag in tags" :key="tag.id" :tag="tag" />
    </draggable>
    <div v-if="shouldShowMoreBtn" class="toggle-tags" @click="showAll = !showAll">
      <div v-if="!showAll" class="toggle-tags-item"><IconDown />show all tags</div>
      <div v-if="showAll" class="toggle-tags-item"><IconUp />show less</div>
    </div>
    <div v-if="!tags || !tags.length" class="empty-message">
      <IconHash />
      Hash tags found in your notes will appear here
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { getDropPosition } from '@/utils/tags';
import IconDown from '@/components/icons/Down';
import IconUp from '@/components/icons/Up';
import IconHash from '@/components/icons/Hash';
import TagItem from '@/components/TagItem';

export default {
  name: 'Tags',
  components: {
    draggable,
    IconDown,
    IconUp,
    IconHash,
    TagItem,
  },
  data: () => ({
    showAll: false,
    tagLimit: 5,
  }),
  computed: {
    allTags() {
      return this.$store.getters['tags/childTags'](null);
    },
    tags() {
      const sorted = [...this.allTags].sort((a, b) => {
        // If they don't have a position, sort by name
        if (!a.position || !b.position || a.position === b.position) {
          return a.name.localeCompare(b.name);
        }
        return a.position - b.position;
      });
      // Return shorter list if not showing all
      return this.showAll ? sorted : [...sorted.slice(0, this.tagLimit)];
    },
    shouldShowMoreBtn() {
      return this.allTags.length > this.tagLimit;
    },
  },
  methods: {
    onDragChange($event) {
      const { element, newIndex } = $event.moved || $event.added || {};
      if (element) {
        // Dispatch edit to note
        this.$store.dispatch('tags/edit', {
          ...element,
          parent: null,
          position: getDropPosition(this.tags, newIndex),
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.empty-message {
  margin-left: 4px;
}
.toggle-tags {
  font-size: 13px;
}
.toggle-tags-item {
  opacity: 0.5;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }

  .icon {
    cursor: move;
    width: 30px;
    height: 30px;
    margin-right: 6px;
    display: grid;
    place-items: center;
    border-radius: 8px;

    /deep/ svg {
      fill: var(--sidebar-text-color);
      width: 14px;
      height: 14px;
    }
  }
}
.empty-message {
  font-size: 14px;
  opacity: 0.5;
  font-weight: 400;
  display: flex;

  .icon {
    margin-right: 12px;
    margin-left: 4px;
    margin-top: 3px;

    /deep/ svg {
      width: 16px;
      height: 16px;
      fill: var(--text-color);
    }
  }
}
</style>
