<template>
  <Days />
</template>

<script>
import Days from '@/components/Days';

export default {
  name: 'Home',
  components: {
    Days,
  },
};
</script>
