<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.58579 1.25244C2.96086 0.87737 3.46957 0.666656 4 0.666656H9.33329H9.33333L9.33646 0.666664C9.49097 0.667383 9.63306 0.720665 9.74577 0.809547C9.76634 0.825744 9.78604 0.843218 9.80474 0.861919L13.8047 4.86192C13.8259 4.88309 13.8455 4.90555 13.8635 4.9291C13.9515 5.04459 14 5.18646 14 5.33332V13.3333C14 13.8638 13.7893 14.3725 13.4142 14.7475C13.0391 15.1226 12.5304 15.3333 12 15.3333H4C3.46957 15.3333 2.96086 15.1226 2.58579 14.7475C2.21071 14.3725 2 13.8638 2 13.3333V2.66666C2 2.13622 2.21071 1.62752 2.58579 1.25244ZM4 1.99999H8.66663V5.33332C8.66663 5.70151 8.9651 5.99999 9.33329 5.99999H12.6667V13.3333C12.6667 13.5101 12.5964 13.6797 12.4714 13.8047C12.3464 13.9298 12.1768 14 12 14H4C3.82319 14 3.65362 13.9298 3.5286 13.8047C3.40357 13.6797 3.33333 13.5101 3.33333 13.3333V2.66666C3.33333 2.48985 3.40357 2.32028 3.5286 2.19525C3.65362 2.07023 3.82319 1.99999 4 1.99999ZM9.99996 2.94276L11.7239 4.66666H9.99996V2.94276ZM5.33329 8C4.9651 8 4.66663 8.29848 4.66663 8.66667C4.66663 9.03486 4.9651 9.33333 5.33329 9.33333H10.6666C11.0348 9.33333 11.3333 9.03486 11.3333 8.66667C11.3333 8.29848 11.0348 8 10.6666 8H5.33329ZM4.66663 11.3333C4.66663 10.9651 4.9651 10.6667 5.33329 10.6667H10.6666C11.0348 10.6667 11.3333 10.9651 11.3333 11.3333C11.3333 11.7015 11.0348 12 10.6666 12H5.33329C4.9651 12 4.66663 11.7015 4.66663 11.3333ZM5.33329 5.33334C4.9651 5.33334 4.66663 5.63182 4.66663 6.00001C4.66663 6.3682 4.9651 6.66668 5.33329 6.66668H6.66663C7.03482 6.66668 7.33329 6.3682 7.33329 6.00001C7.33329 5.63182 7.03482 5.33334 6.66663 5.33334H5.33329Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconMenuText',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
