<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.70711 5.71681C7.31658 5.32629 6.68342 5.32629 6.29289 5.71681C5.90237 6.10734 5.90237 6.7405 6.29289 7.13103L11.2929 12.131C11.6834 12.5216 12.3166 12.5216 12.7071 12.131L17.7071 7.13103C18.0976 6.7405 18.0976 6.10734 17.7071 5.71681C17.3166 5.32629 16.6834 5.32629 16.2929 5.71681L12 10.0097L7.70711 5.71681ZM7.70711 12.7168C7.31658 12.3263 6.68342 12.3263 6.29289 12.7168C5.90237 13.1073 5.90237 13.7405 6.29289 14.131L11.2929 19.131C11.6834 19.5216 12.3166 19.5216 12.7071 19.131L17.7071 14.131C18.0976 13.7405 18.0976 13.1073 17.7071 12.7168C17.3166 12.3263 16.6834 12.3263 16.2929 12.7168L12 17.0097L7.70711 12.7168Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconChevronDown',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
