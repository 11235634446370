import dayjs from 'dayjs';

import { getNotes, storeNote, queueUpdateNote } from '@/db';
import { generateId } from '@/utils';
import { getSeedNotes } from '@/data/seed-notes';

const STORAGE_KEY = process.env.VUE_APP_STORAGE_KEY;

const state = {
  notes: [],
};

const getters = {
  notes: (state) => state.notes,
  notesByDate:
    (state) =>
    (date, parentId = null) => {
      const match = state.notes.filter((note) => note.parent === parentId && note.listDate === date);
      return match.sort((a, b) => a.position - b.position);
    },
  notesByType: (state) => (type) => state.notes.filter((note) => note.type === type),
  notesBookmarked: (state) => state.notes.filter((note) => note.marked),
  notesByTag: (state) => (tag) => state.notes.filter((note) => note.tags && note.tags.includes(tag)),
  childNotes: (state) => (id) => {
    const match = state.notes.filter((note) => note.parent === id);
    return match.sort((a, b) => a.position - b.position);
  },
  noteById: (state) => (id) => state.notes.find((note) => note.id === id),
  notesWithTags: (state) => state.notes.filter((note) => note.tags && note.tags.length),
  noteTags: (state) => {
    const notes = state.notes.filter((note) => note.tags && note.tags.length);
    const tags = notes.reduce((accumulator, note) => accumulator.concat(note.tags), []);
    return [...new Set(tags)];
  },
  notesAsTaskCount: (state) => state.notes.filter((note) => note.type === 'task' && !note.task.completed).length,
};

const mutations = {
  set: (state, notes) => {
    state.notes = notes;
  },
  add(state, note) {
    state.notes.push(note);
  },
  edit(state, note) {
    const index = state.notes.findIndex((x) => x.id === note.id);
    state.notes.splice(index, 1, note);
  },
  remove(state, noteId) {
    const index = state.notes.findIndex((x) => x.id === noteId);
    state.notes.splice(index, 1);
  },
};

const actions = {
  async fetchNotes({ commit, dispatch }) {
    // Fetch notes
    const notes = await getNotes();
    if (notes && notes.length) {
      commit('set', notes);
      // Trigger Tree Calc
      dispatch('noteTree/recalculate', null, { root: true });
    } else {
      // DB is empty, use Seed data
      const notes = getSeedNotes();
      if (notes && notes.length) {
        // and commit to state
        notes.forEach((note) => {
          commit('add', note); // put in state
        });
      }
    }
  },
  add({ commit }, note) {
    const id = generateId();
    const now = dayjs();
    const nowStr = now.format();
    const newNote = {
      id,
      parent: null,
      createdDate: nowStr,
      modifiedDate: nowStr,
      ...note,
    };
    // Commite to State
    commit('add', newNote);
    // Store in DB
    storeNote(newNote);
    // Return the note ID
    return id;
  },
  edit({ commit }, note) {
    const nowStr = dayjs().format();
    const updatedNote = {
      ...note,
      modifiedDate: nowStr,
    };
    // Update in State
    commit('edit', updatedNote);
    // Save in DB if note readonly
    if (!note.readOnly) queueUpdateNote('edit', updatedNote.id, updatedNote);
  },
  remove({ commit }, noteId) {
    // Remove from State
    commit('remove', noteId);
    // Delete from DB
    queueUpdateNote('delete', noteId, noteId);
  },
  batchRemove({ commit }, ids) {
    ids.forEach((noteId) => {
      // Remove from State
      commit('remove', noteId);
      // Delete from DB
      queueUpdateNote('delete', noteId, noteId);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
