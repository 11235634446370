// helper methods for copying and pasting text
import Vue from 'vue';

// https://github.com/html-to-text/node-html-to-text
import { htmlToText } from 'html-to-text';
import { execCommand, getSelectedNotesAsText } from './dom';
import { replaceNewLines } from './text';

// Handles pasting into a note
// USES execCommand (which is deprecated)
const pasteTextIntoNote = (e) => {
  e.preventDefault();
  const event = e.originalEvent || e;
  let clipboard = event.clipboardData.getData('text/html');
  clipboard = clipboard || event.clipboardData.getData('text/plain');
  // Clean incoming text
  const { string } = cleanPastedText(clipboard);
  execCommand('insertHTML', string);
};

// Cleans up raw pasted text
const cleanPastedText = (html) => {
  const text = htmlToText(html, {
    wordwrap: null,
    uppercase: false,
  });
  const string = replaceNewLines(text);
  return { string, text };
};

// converts text object to markdown
// @in accepts array of dom elements
// @out returns array of text/markdown
const convertTextToMarkdown = (textArray) => {
  if (!textArray) return [''];

  // if there is only one element in the array,
  // just return it as a simple string
  if (textArray.length === 1) {
    return [`${textArray[0].content}`];
  }

  // else map through types and return as markdown
  return textArray.map((textObj) => {
    // determines how much to tab in
    const indentAmount = Number(textObj.level);
    const tabIndent = indentAmount ? '\t'.repeat(indentAmount) : '';

    // converts text object to appropriate markdown
    switch (textObj.type) {
      case 'note':
        return `${tabIndent}- ${textObj.content}`;
      case 'task':
        return `${tabIndent}- [ ] ${textObj.content}`;
      case 'completedTask':
        return `${tabIndent}- [x] ${textObj.content}`;
      case 'event':
        return `${tabIndent}- **${textObj.content}**`;
      default:
        return `${textObj.content}`;
    }
  });
};

// gets selected notes, cleans, parses and adds to clipboard
const copySelectedTextToClipboard = async () => {
  const textArr = getSelectedNotesAsText();
  const textArrayMD = convertTextToMarkdown(textArr);
  const text = textArrayMD.join('\n');

  if (text) {
    // If text exists, the add it to the clipboard
    try {
      await navigator.clipboard.writeText(text);
      console.log('Copying to clipboard was successful!');
      Vue.notify('Copied to clipboard');
    } catch (err) {
      console.error('Could not copy text: ', err);
      Vue.notify('Could not copy the text');
    }
  }
};

export { cleanPastedText, copySelectedTextToClipboard, pasteTextIntoNote };
