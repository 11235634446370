<template>
  <div class="refresh" @click="refreshClicked()">An update is available. <span>Reload Tueday</span></div>
</template>

<script>
export default {
  name: 'RefreshButton',
  methods: {
    refreshClicked() {
      this.$emit('requestRefresh');
    },
  },
};
</script>

<style scoped lang="scss">
.refresh {
  z-index: 20;
  background: var(--refresh-button-background);
  color: var(--refresh-button-color);
  position: fixed;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  cursor: pointer;
  padding: 5px 12px;
  line-height: 18px;
  border-radius: 14px;
  text-align: center;

  &:hover {
    background: var(--refresh-button-hover-background);
  }

  span {
    text-decoration: underline;
  }

  @media (min-width: $tablet) {
    bottom: 2rem;
  }
}
</style>
