// Route Names
export const PAGE_HOME = 'Home';
export const PAGE_TASKS = 'Tasks';
export const PAGE_BOOKMARKS = 'Bookmarks';
export const PAGE_TAG = 'Tag';
export const PAGE_MEETINGS = 'Meetings';
export const PAGE_SEARCH = 'Search';
export const PAGE_ABOUT = 'About';
export const PAGE_NOTE = 'Note';
export const PAGE_DAY = 'Day';

// Defines size of gap between drag and drop nodes
export const POSITION_INCREMENT = 100;

// Task Object
export const TASK_OBJ = {
  completed: false,
  dueDate: null,
  completedDate: null,
  group: null,
  position: POSITION_INCREMENT,
};

export const TASK_GROUP_TODO = 'todo';
export const TASK_GROUP_LATER = 'later';

// default settings on startup
// These are stored against the User Profile
export const DEFAULT_SETTINGS = {
  darkMode: false,
};

// default config on startup
// These are device specific
export const DEFAULT_CONFIG = {
  welcomeOpen: true,
  acceptedWelcome: false,
  sidebarOpen: true,
  todoListOpen: true,
  todoLaterListOpen: true,
};
