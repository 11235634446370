<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.25237 1.58326C5.42899 1.5838 5.59125 1.6454 5.71913 1.74806C5.74043 1.76513 5.76088 1.78343 5.78037 1.80292L8.69704 4.71959C8.98993 5.01248 8.98993 5.48736 8.69704 5.78025C8.40414 6.07314 7.92927 6.07314 7.63638 5.78025L6 4.14387V9.33325C6 9.75318 6.16681 10.1559 6.46375 10.4528C6.76068 10.7498 7.16341 10.9166 7.58333 10.9166H11.6667C12.0809 10.9166 12.4167 11.2524 12.4167 11.6666C12.4167 12.0808 12.0809 12.4166 11.6667 12.4166H7.58333C6.76558 12.4166 5.98132 12.0917 5.40309 11.5135C4.82485 10.9353 4.5 10.151 4.5 9.33325V4.14395L2.8637 5.78025C2.57081 6.07314 2.09594 6.07314 1.80304 5.78025C1.51015 5.48736 1.51015 5.01248 1.80304 4.71959L4.71506 1.80758C4.85113 1.66912 5.04054 1.58325 5.25 1.58325C5.25001 1.58325 5.25003 1.58325 5.25004 1.58325"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconLevelDown',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
