<template>
  <div class="wrapper">
    <div v-if="open" class="overlay" @click="closeMenu" />
    <transition name="open">
      <Menu v-if="showContent" ref="noteMenu" :style="positionObj" class="note-menu">
        <div v-if="type === 'task'" class="menu-group">
          <a v-if="completed" class="menu-item" href="" @click="completeTask($event, 'task')">
            <div class="icon"><NoteBullet type="task" :completed="true" /></div>
            Completed
          </a>
          <a v-if="!completed" class="menu-item" href="" @click="completeTask($event, 'task')">
            <div class="icon"><NoteBullet type="task" /></div>
            Complete task
          </a>
        </div>
        <div class="menu-group">
          <div class="menu-title">Convert to</div>
          <a v-if="type !== 'note'" class="menu-item" href="" @click="changeType($event, 'note')">
            <div class="icon"><NoteBullet type="note" /></div>
            Note
          </a>
          <a v-if="type !== 'task'" class="menu-item" href="" @click="changeType($event, 'task')">
            <div class="icon"><NoteBullet type="task" /></div>
            Task
          </a>
          <a v-if="type !== 'event'" class="menu-item" href="" @click="changeType($event, 'event')">
            <div class="icon"><NoteBullet type="event" /></div>
            Meeting
          </a>
        </div>
        <div class="menu-group">
          <div class="menu-title">More options</div>
          <a v-if="hasChildren" class="menu-item" href="" @click="togleChildren($event)">
            <span v-if="!hideChildren" class="menu-item-row">
              <IconChevronUp />
              Hide children
            </span>
            <span v-if="hideChildren" class="menu-item-row">
              <IconChevronDown />
              Show children
            </span>
          </a>
          <a class="menu-item" href="" @click="bookmarkNote($event)">
            <IconBookmark />
            {{ marked ? 'Remove Bookmark' : 'Bookmark this' }}
          </a>
          <a class="menu-item" href="" @click="goToNote($event)">
            <IconArrowDownRight />
            Zoom into note
          </a>
          <a class="menu-item" href="" @click="goToParent($event)">
            <IconArrowRightUp />
            See in context
          </a>
        </div>
        <div class="menu-group">
          <a class="menu-item" href="" @click="triggerDelete($event)">
            <IconTrash />
            Delete
          </a>
        </div>
      </Menu>
    </transition>
  </div>
</template>

<script>
import IconTrash from '@/components/icons/Trash';
import IconChevronDown from '@/components/icons/ChevronDown';
import IconChevronUp from '@/components/icons/ChevronUp';
import IconBookmark from '@/components/icons/Bookmark';
import IconArrowDownRight from '@/components/icons/ArrowDownRight';
import IconArrowRightUp from '@/components/icons/ArrowRightUp';
import IconClock from '@/components/icons/Clock';
import NoteBullet from '@/components/NoteBullet';
import Menu from '@/components/Menu';

export default {
  name: 'NoteMenu',
  components: {
    IconChevronDown,
    IconChevronUp,
    IconBookmark,
    IconArrowDownRight,
    IconArrowRightUp,
    IconTrash,
    Menu,
    NoteBullet,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'note',
    },
    marked: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    isToDoLater: {
      type: Boolean,
      default: false,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    hideChildren: {
      type: Boolean,
      default: false,
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showContent: false,
    windowTop: 0,
    positionObj: {
      top: '0px',
      left: '0px',
      position: 'absolute',
    },
  }),
  mounted() {
    this.showContent = true;
    this.updatePositionObj();
  },
  methods: {
    closeMenu() {
      this.$emit('toggleMenu');
    },
    changeType(e, type) {
      e.preventDefault();
      this.$emit('changeType', type);
    },
    markImportant(e) {
      e.preventDefault();
      this.$emit('toggleImportant');
    },
    completeTask(e) {
      e.preventDefault();
      this.$emit('completeTask');
    },
    changeTaskGroup(e, group) {
      e.preventDefault();
      this.$emit('changeTaskGroup', group);
    },
    togleChildren(e) {
      e.preventDefault();
      this.$emit('toggleChildren');
    },
    bookmarkNote(e) {
      e.preventDefault();
      this.$emit('toggleBookmark');
    },
    triggerDelete(e) {
      e.preventDefault();
      if (this.hasChildren) {
        if (confirm('Deleting this note will delete its children as well. Continue?')) {
          this.$emit('deleteNote');
        }
      } else {
        this.$emit('deleteNote');
      }
    },
    goToNote(e) {
      e.preventDefault();
      this.$emit('navigateToNote');
      this.closeMenu();
    },
    goToParent(e) {
      e.preventDefault();
      this.$emit('navigateToRootParent');
      this.closeMenu();
    },
    updatePositionObj() {
      const menuOffset = 32; // feels right...
      const menuHeight = 355; // rough height
      // if notemenu is fixed, then dont use the window scroll offset
      // and set position: fixed
      const topOffset = this.isFixed ? 0 : window.scrollY;
      let top = this.position.top + topOffset;
      let left = this.position.left < 50 ? 60 : this.position.left;

      // this needs some work :D
      if (this.isFixed && top + menuHeight > window.innerHeight) {
        // if is fixed, double check its not going off screen
        top = window.innerHeight - menuHeight + menuOffset;
      }

      // build and update position obj
      this.positionObj = {
        top: `${top}px`,
        left: `${left}px`,
        position: this.isFixed ? 'fixed' : 'absolute',
      };
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  z-index: $zIndexNotesMenu;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: $zIndexNotesMenu + 1;
}
.note-menu {
  margin-left: -3rem;
  margin-top: -2rem;
  z-index: $zIndexNotesMenu + 2;
}
.open-enter-active,
.open-leave-active {
  transition: all 0.2s;
  opacity: 1;
  transform: scale(1);
}
.open-enter, .open-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0.9);
}
</style>
