<template>
  <div class="logo" @click="logoClicked()">
    <svg width="69" height="21" viewBox="0 0 69 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.78194 15.7133V7.77899H12.5002V6H5V7.77899H7.7183V15.7133H9.78194Z" fill="#212226" />
      <path
        d="M17.096 6H15.0395V12.3332C15.0395 14.4822 16.5979 15.8983 19.0102 15.8983C21.4225 15.8983 22.9809 14.4965 22.9809 12.3475V6H20.9173V12.1197C20.9173 13.3935 20.1488 14.0838 19.0102 14.0838C17.8574 14.0838 17.096 13.4006 17.096 12.1197V6Z"
        fill="#212226"
      />
      <path
        d="M32.7572 13.9841H28.4591V11.6145H32.501V10.0134H28.4591V7.72918H32.7572V6H26.4168V15.7133H32.7572V13.9841Z"
        fill="#212226"
      />
      <path
        d="M36.0223 6V15.7133H39.6585C42.8038 15.7133 44.0135 13.6853 44.0135 10.8246C44.0135 7.96401 42.7967 6 39.6585 6H36.0223ZM38.0859 7.72207H39.3596C41.3237 7.72207 41.9001 9.10968 41.9001 10.8744C41.9001 13.2441 40.9465 13.9841 39.3596 13.9841H38.0859V7.72207Z"
        fill="#212226"
      />
      <path
        d="M47.9546 15.7133L48.6804 13.3579H51.847L52.5586 15.7133H54.7646L51.5624 6H49.1145L45.8981 15.7133H47.9546ZM50.3242 7.98536L51.4486 11.7853H49.086L50.2246 7.98536H50.3242Z"
        fill="#212226"
      />
      <path
        d="M60.748 15.7133V12.134L64 6H61.7869L59.7873 10.0561H59.702L57.7024 6H55.4324L58.6844 12.1838V15.7133H60.748Z"
        fill="#212226"
      />
    </svg>
  </div>
</template>

<script>
import { PAGE_HOME } from '@/common/constants';

export default {
  name: 'Logo',
  methods: {
    logoClicked() {
      if (this.$router.currentRoute && this.$router.currentRoute.name == PAGE_HOME) {
        window.scrollTo(0, 0);
      } else {
        this.$router.push({ name: PAGE_HOME });
      }
      this.$gtag.event('ClickLogo', {
        event_category: 'Header',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  display: block;
  width: 90px;
  height: 27px;
  background: $color_primary;
  cursor: pointer;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
