<template>
  <div class="listing nl">
    <NotesGroup>
      <NotesLayout
        :subTitle="subTitle"
        :headerNote="true"
        :childNotesOnly="true"
        :hideContext="true"
        :listDate="listDate"
        :showAddNote="false"
        :showEmptyMessage="false"
        :notes="notes"
      />
    </NotesGroup>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import NotesGroup from '@/components/NotesGroup';
import NotesLayout from '@/components/NotesLayout';

export default {
  name: 'NoteDeeplink',
  components: {
    NotesGroup,
    NotesLayout,
  },
  computed: {
    notes() {
      const noteId = this.$route.params.noteId;
      const note = this.$store.getters['notes/noteById'](noteId);
      return note ? [note] : [];
    },
    subTitle() {
      return this.notes.length ? dayjs(this.notes[0].listDate).format('D MMM YYYY') : '';
    },
    listDate() {
      return this.notes[0] && this.notes[0].listDate;
    },
  },
};
</script>
