<template>
  <button class="button" @click="onClick()">
    <ArrowRightUp />
    Back to Today
  </button>
</template>

<script>
import ArrowRightUp from '@/components/icons/ArrowRightUp';

export default {
  name: 'BackToTopBtn',
  components: {
    ArrowRightUp,
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  background: var(--notification-button-background);
  color: var(--notification-button-color);
  font-size: 14px;
  padding: 0 12px;
  line-height: 28px;
  border-radius: 14px;
  border: none;
  cursor: pointer;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  z-index: $zIndexContent + 1;
  transition: transform 125ms linear;

  &:hover {
    opacity: 0.9;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;
  }

  /deep/ svg {
    fill: var(--notification-button-color);
  }

  .show {
    transform: translateY(0rem);
  }

  &.hide {
    transform: translateY(4rem);
  }
}
</style>
