<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0.444444C0 0.198985 0.198985 0 0.444444 0C0.689904 0 0.888889 0.198985 0.888889 0.444444V3.55556C0.888889 3.90918 1.02936 4.24832 1.27941 4.49836C1.52946 4.74841 1.8686 4.88889 2.22222 4.88889H6.48249L5.01906 3.42546C4.8455 3.2519 4.8455 2.97049 5.01906 2.79692C5.19263 2.62336 5.47404 2.62336 5.6476 2.79692L7.86471 5.01403C7.94814 5.09482 8 5.20802 8 5.33333C8 5.38151 7.99234 5.42789 7.97816 5.47133C7.95717 5.53581 7.92106 5.59645 7.86983 5.64768L5.6476 7.86991C5.47404 8.04347 5.19263 8.04347 5.01906 7.86991C4.8455 7.69634 4.8455 7.41493 5.01906 7.24137L6.48265 5.77778H2.22222C1.63285 5.77778 1.06762 5.54365 0.650874 5.1269C0.234126 4.71016 0 4.14493 0 3.55556V0.444444Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconLevelDown',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
  width: 8px;
  height: 8px;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
