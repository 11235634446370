import { POSITION_INCREMENT } from '@/common/constants';

// Calculates the Tag Position # on DragnDrop
const getDropPosition = (tags, index) => {
  let newPosition = POSITION_INCREMENT; // used if list is empty
  const prevItem = tags[index == 0 ? false : index - 1];
  const nextItem = tags[index == tags.length - 1 ? false : index + 1];

  if (prevItem && nextItem) {
    // inbetween two items
    newPosition = (nextItem.position - prevItem.position) / 2 + prevItem.position;
  } else if (!prevItem && nextItem) {
    // start of list
    newPosition = nextItem.position / 2;
  } else if (prevItem && !nextItem) {
    // end of list
    newPosition = prevItem.position + POSITION_INCREMENT;
  }

  return isNaN(newPosition) ? POSITION_INCREMENT : newPosition;
};

export { getDropPosition };
