<template>
  <div class="box" :class="{ marked: marked }">
    <div v-if="type === 'note'" class="dot" />
    <div v-if="type === 'event'" class="doc"><span /></div>
    <div v-if="type === 'task' && !completed" class="circle" />
    <div v-if="type === 'task' && completed" class="circle-check" />
  </div>
</template>

<script>
export default {
  name: 'AddNote',
  components: {},
  props: {
    type: {
      type: String,
      default: 'note',
    },
    completed: {
      type: Boolean,
      default: false,
    },
    marked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.dot {
  width: 6px;
  height: 6px;
  background: var(--text-color);
  border-radius: 50%;
  box-shadow: 0 0 0 4px var(--icon-bullet-outline);

  .marked & {
    box-shadow: 0 0 0 4px var(--bookmark-highlight);
  }
}
.circle {
  width: 12px;
  height: 12px;
  border: solid 2px var(--text-color);
  border-radius: 50%;

  .marked & {
    background-color: var(--bookmark-highlight);
  }
}
.circle-check {
  width: 14px;
  height: 14px;
  background: var(--icon-bullet-outline);
  border-radius: 50%;
  position: relative;

  .marked & {
    background-color: var(--bookmark-highlight);
  }

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 3px;
    transform: rotate(-45deg);
    width: 12px;
    height: 2px;
    background: var(--text-color);
  }

  &:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 1px;
    transform: rotate(45deg);
    width: 6px;
    height: 2px;
    background: var(--text-color);
  }
}
.square {
  width: 10px;
  height: 10px;
  border: solid 2px var(--text-color);
  border-radius: 2px;
  transform: rotate(45deg);

  .marked & {
    background-color: var(--bookmark-highlight);
  }
}
.doc {
  width: 10px;
  height: 2px;
  background: var(--text-color);
  border-radius: 2px;
  position: relative;

  span {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 10px;
    height: 2px;
    background: inherit;
  }

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 5px;
    height: 2px;
    background: inherit;
  }

  .marked & span {
    width: 14px;
    height: 14px;
    display: block;
    background: var(--bookmark-highlight);
    position: absolute;
    top: -6px;
    left: -2px;
    z-index: -1;
    border-radius: 2px;
  }
}
</style>
