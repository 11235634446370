<template>
  <div class="group">
    <div class="grid">
      <div class="spacer" :class="{ sidebarOpen: sidebarOpen }" />
      <div class="content">
        <slot />
      </div>
      <div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotesGroup',
  computed: {
    sidebarOpen() {
      return this.$store.getters.getConfig.sidebarOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.group {
  width: 100%;
  padding-top: 2rem;
}
.group + .group {
  border-top: dashed 1px var(--week-border-color);
}
@media (min-width: $tablet) {
  .group {
    padding-top: 4rem;
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr minmax(200px, 558px) 1fr;
  width: 100%;
  padding: 0 1.5rem 0 0;
}
@media (min-width: $tablet) {
  .grid {
    padding: 0 2rem;
  }
}
.spacer {
  width: 1.5rem;
}
@media (min-width: $tablet) {
  .spacer {
    width: 100px;

    &.sidebarOpen {
      width: $sidebar_width;
    }
  }
}
.content {
  /* Content is below sidebar on mobile */
  z-index: $zIndexSidebar - 1;
}
@media (min-width: $tablet) {
  /* If sidebar is closed, content sits above it */
  .content {
    z-index: $zIndexSidebar + 1;
  }
  /* If sidebar is open, content sits below it */
  .sidebarOpen + .content {
    z-index: $zIndexSidebar - 1;
  }
}
</style>
