<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 6.5L12.2353 6.5L7.99264 10.7426L3.75 6.5Z" :fill="color" />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconToggle',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
