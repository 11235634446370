<!-- eslint-disable -->
<template>
  <span class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0C12.5523 0 13 0.447715 13 1V3C13 3.55228 12.5523 4 12 4C11.4477 4 11 3.55228 11 3V1C11 0.447715 11.4477 0 12 0ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12ZM13 21C13 20.4477 12.5523 20 12 20C11.4477 20 11 20.4477 11 21V23C11 23.5523 11.4477 24 12 24C12.5523 24 13 23.5523 13 23V21ZM3.51286 3.51289C3.90339 3.12237 4.53655 3.12237 4.92708 3.51289L6.34708 4.93289C6.7376 5.32342 6.7376 5.95658 6.34708 6.34711C5.95655 6.73763 5.32339 6.73763 4.93286 6.34711L3.51286 4.92711C3.12234 4.53658 3.12234 3.90342 3.51286 3.51289ZM19.0671 17.6529C18.6766 17.2624 18.0434 17.2624 17.6529 17.6529C17.2624 18.0434 17.2624 18.6766 17.6529 19.0671L19.0729 20.4871C19.4634 20.8776 20.0966 20.8776 20.4871 20.4871C20.8776 20.0966 20.8776 19.4634 20.4871 19.0729L19.0671 17.6529ZM0 12C0 11.4477 0.447715 11 1 11H3C3.55228 11 4 11.4477 4 12C4 12.5523 3.55228 13 3 13H1C0.447715 13 0 12.5523 0 12ZM21 11C20.4477 11 20 11.4477 20 12C20 12.5523 20.4477 13 21 13H23C23.5523 13 24 12.5523 24 12C24 11.4477 23.5523 11 23 11H21ZM6.34708 17.6529C6.7376 18.0434 6.7376 18.6766 6.34708 19.0671L4.92708 20.4871C4.53655 20.8776 3.90339 20.8776 3.51286 20.4871C3.12234 20.0966 3.12234 19.4634 3.51286 19.0729L4.93286 17.6529C5.32339 17.2624 5.95655 17.2624 6.34708 17.6529ZM20.4871 4.92711C20.8776 4.53658 20.8776 3.90342 20.4871 3.51289C20.0966 3.12237 19.4634 3.12237 19.0729 3.51289L17.6529 4.93289C17.2624 5.32342 17.2624 5.95658 17.6529 6.34711C18.0434 6.73763 18.6766 6.73763 19.0671 6.34711L20.4871 4.92711Z"
        :fill="color"
      />
    </svg>
  </span>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconSun',
  props: ['color'],
};
</script>

<style scoped>
.icon {
  display: inline-block;
}
.icon svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>
