import { render, staticRenderFns } from "./MenuText.vue?vue&type=template&id=1cf3070d&scoped=true&"
import script from "./MenuText.vue?vue&type=script&lang=js&"
export * from "./MenuText.vue?vue&type=script&lang=js&"
import style0 from "./MenuText.vue?vue&type=style&index=0&id=1cf3070d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cf3070d",
  null
  
)

export default component.exports