<template>
  <div class="add-note" @click="addNote">
    <PlusIcon color="#cacaca" />
    {{ label }}
  </div>
</template>

<script>
import PlusIcon from '@/components/icons/Plus';

export default {
  name: 'AddNote',
  components: {
    PlusIcon,
  },
  props: {
    label: {
      type: String,
      default: 'Add a note',
    },
  },
  methods: {
    addNote() {
      this.$emit('addNote');
    },
  },
};
</script>

<style scoped lang="scss">
.add-note {
  width: 100%;
  color: var(--text-placeholder-color);
  margin-bottom: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 4px;
  }

  &:hover {
    color: #969696;
  }
}
</style>
